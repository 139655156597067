import React from 'react'
import {
  MainFooter,
  HeaderBooking,
  FirstScreenPrice,
  SecondScreenPrice
} from '../../components/index'
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { clearFields, setGoogleAPILoaded } from '../../modules/info/actions'
import { getGoogleMapsKey } from '../../modules/requests/actions'

export const PricesComponent = (props) => {
  const { t } = useTranslation();

  const [pricesPageState, setPricesPageState] = React.useState(0);
  const [googleState, setGoogleState] = React.useState('');

  // Cleanup previous data and Connect to Google Maps
  React.useEffect(() => {
    //console.log('-> pricesPageState='+pricesPageState+'; googleMapsKey='+props.googleMapsKey+'; googleState='+googleState);
    if (pricesPageState === 0) {

      props.clearFields();
      setPricesPageState(1);
    } else {
      if (googleState === '') {

        if (props.googleMapsKey === null || props.googleMapsKey === undefined) {
          props.getGoogleMapsKey();
        } else if (props.googleMapsKey.length) {
          const script = document.createElement("script");

          script.type="text/javascript";
          script.src="https://maps.googleapis.com/maps/api/js?key="+props.googleMapsKey+"&libraries=places,establishment,geometry,drawing";
          script.addEventListener("load", () => { console.log('script loaded'); setGoogleState('complete')});
          script.addEventListener("error", () => { console.log('script load error'); setGoogleState('error')});

          document.body.appendChild(script);
          setGoogleState('loading');
        }
      } else if (googleState === 'complete') {
        props.setGoogleAPILoaded(true);
      }
    }
  }, [pricesPageState, props.googleMapsKey, googleState]);

  return (
    <div>
      <HeaderBooking
        headerText={t("prices.title")}
        prices
        {...props}
      />
      <FirstScreenPrice />
      <SecondScreenPrice />
      <MainFooter />
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  googleMapsKey: userInfo.googleMapsKey
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFields,
  getGoogleMapsKey,
  setGoogleAPILoaded
}, dispatch);

export const Prices = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricesComponent);