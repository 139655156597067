import React from 'react'
import styles from './bookingcontroll.module.css';
import error from '../../assets/Error.svg'
import cn from 'classnames'
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  clearPays
} from '../../modules/info/actions'
import './animation.css'

function paramsToObject(entries) {
  let result = {};
  for(let entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}
const successPayment = [0];
const returnBank = [1];
const technical = [3, 12, 13, 30, 57, 58, 63, 77, 80, 81, 91, 92, 96, 97, 99];
const errorCard = [4, 7, 33, 34, 36, 37, 38, 41, 43, 59, 62, 75];
const errorBank = [5, 85];
const errorData = [14, 15, 82, 84];
const needMoney = [51];
const notTime = [54];
const limit = [61, 65];

const getErrorText = (id) => {
  if (successPayment.some((item) => item === id)) {
    return 'errorCods.successPayment'
  } else if (returnBank.some((item) => item === id)) {
    return 'errorCods.returnBank'
  } else if (technical.some((item) => item === id)) {
    return 'errorCods.technical'
  } else if (errorCard.some((item) => item === id)) {
    return 'errorCods.errorCard'
  } else if (errorBank.some((item) => item === id)) {
    return 'errorCods.errorBank'
  } else if (errorData.some((item) => item === id)) {
    return 'errorCods.errorData'
  } else if (needMoney.some((item) => item === id)) {
    return 'errorCods.needMoney'
  } else if (notTime.some((item) => item === id)) {
    return 'errorCods.notTime'
  } else if (limit.some((item) => item === id)) {
    return 'errorCods.limit'
  } else {
    return 'errorCods.default'
  }
};

export const FailedComponent = ({
  step,
  setStep,
  clearPays,
  startPoint,
  endPoint,
  history,
  returnCode,
  email
}) => {
  const { t } = useTranslation();

  const tryAgain = () => {
    if (step === 4 || step === 5) {
      history.push('/booking')
    } else {
      clearPays();
      history.push('/')
    }
    setStep(2);
  };

  return (
    <div>
      <h1 className={styles.title}>
        Failed
      </h1>
      <div className={styles.progress}>
        <img src={error} />
      </div>
      <span className={styles.textBold}>
        Something went wrong
      </span>
      <span className={styles.textLightError}>
        {t(getErrorText(returnCode))}
      </span>
      <span className={cn(styles.textLight, styles.tryagain)}>
        <span onClick={tryAgain}>{t("done.tryAgain")}</span>
      </span>
      <span className={styles.textLight}>
        {t("done.textTwo")} {t("progress.textThree")} <span>+90 552 614 56 69</span>
      </span>
    </div>
  )
};

const mapStateToProps = ({ payment, userInfo }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  returnCode: userInfo.returnCode,
  email: userInfo.email,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearPays
}, dispatch);

export const Failed = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FailedComponent);