import React from 'react'
import styles from './secondScreenPrice.module.css';
import {
  getPopularPlacesPrice
} from '../../modules/requests/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";

const currencies = {
  USD: '$',
  EUR: '€',
  TRY: '₺',
  RUB: '₽',
};

export const SecondScreenPriceComponent = ({ getPopularPlacesPrice, popularPlace, currency }) => {
  React.useEffect(() => {
    getPopularPlacesPrice()
  }, [currency]);
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t("prices.yourPrice")}</div>
        <div className={styles.content}>
          {
            popularPlace && popularPlace.map((item, index) => (
              <div className={styles.block} key={index}>
                <div className={styles.text}>
                  {item.name && item.name.trim().split('-')[0]}<br />
                  {item.name && item.name.trim().split('-')[1]}
                </div>
                <div className={styles.price}>
                  {currencies[item.currency]}{item.basicCost}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  popularPlace: payment.popularPlace.data,
  currency: payment.currency
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPopularPlacesPrice,
}, dispatch);

export const SecondScreenPrice = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecondScreenPriceComponent);