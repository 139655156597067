import React from 'react'
import { MapComponent } from '../Map'
import styles from './mainmaps.module.css';
import { useHistory } from "react-router-dom";
import cn from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import 'react-google-places-autocomplete/dist/assets/index.css';
import { AddressField, TextField } from '../../formfields'
import {
  reqStartPoint,
  reqEndPoint,
  setStartPoint,
  setEndPoint
} from '../../modules/info/actions'

export const MainMapsContainer = ({
  reqStartPoint,
  reqEndPoint,
  startPoint,
  endPoint,
  setStartPoint,
  setEndPoint
}) => {
  const history = useHistory();

  const saveTransfer = () => {
    history.push('/booking')
  };

  const handlerPoint = (props, type) => {
    if (type === 'start') {
      reqStartPoint(props)
    } else if (type === 'end') {
      reqEndPoint(props)
    }
  };

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <span className={styles.title}>
          {t("main.map.workingArea")}
        </span>
        <span className={styles.derectionName}>
          Antalya – Alania
        </span>
        <p className={styles.description}>
          {t("main.map.description")}
        </p>
        <span className={styles.textTransfer}>
          {t("main.map.getYourTransfer")}
        </span>
        <div className={styles.form}>
          <div className={styles.firstInput}>
            <AddressField
              fieldnumber={400}
              label={t('main.searchBlock.pickUpLocation')}
              value={startPoint.name}
              fillCoords={(props) => handlerPoint(props, 'start')}
              fillDescx={(e) => {
                const newPoint = {...startPoint};
                newPoint.name = e.target.value;
                setStartPoint(newPoint);
              }}
              setPointData={(item) => setStartPoint({
                id: item.id,
                name: item.description,
                latitude: item.latitude,
                longitude: item.longitude,
                tariffZoneId: item.tariffZoneId
              })}
            />
          </div>

          <div className={styles.secondInput}>
            <AddressField
              fieldnumber={401}
              label={t('main.searchBlock.dropOffLocation')}
              value={endPoint.name}
              fillCoords={(props) => handlerPoint(props, 'end')}
              fillDescx={(e) => {
                const newPoint = {...endPoint};
                newPoint.name = e.target.value;
                setEndPoint(newPoint);
              }}
              setPointData={(item) => setEndPoint({
                id: item.id,
                name: item.description,
                latitude: item.latitude,
                longitude: item.longitude,
                tariffZoneId: item.tariffZoneId
              })}
            />
          </div>
        </div>
        <button
          className={styles.button}
          onClick={saveTransfer}
        >
          {t("main.map.findATransfer")}
        </button>
      </div>
      <div className={styles.right}>
        <MapComponent />
      </div>
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  reqEndPoint,
  reqStartPoint,
  setStartPoint,
  setEndPoint
}, dispatch);

export const MainMaps = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMapsContainer);