import React from 'react'
import styles from './button.module.css';
import cn from 'classnames'

export const Button = ({ text, noFill, onClick, bordered, disabled, indented }) => {
  return (
    <button
      className={cn(styles.button, {[styles.noFill]: noFill, [styles.bordered]: bordered, [styles.disabled]: disabled, [styles.indented]: indented})}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  )
};