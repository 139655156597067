import {
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_ERROR,
  GET_PLACES_PRICE_SUCCESS,
  SET_CURRENCY,
  GET_POPULAR_PLACE,
  GET_ROAD_COST,
  GET_ROAD_COST_ERROR,
  GET_CARD_INFO,
  GET_CARD_INFO_ERROR,
  GET_CARS_ERROR,
  GET_CARTYPES_ERROR,
  SET_LANGUAGE,
  CLEAR_ROAD_COST,
  CLEAR_PAYS
} from './constants'
import { requestsActionTypes } from '../requests/constants'

const language = window.navigator ? (window.navigator.language ||
  window.navigator.systemLanguage ||
  window.navigator.userLanguage) : "ru";
const defaultLanguage = language.substr(0, 2).toLowerCase();

const initialState = {
  pays: {
    data: null,
    loading: false,
    error: null,
  },
  placePrice: {
    data: null,
    loading: false
  },
  currency: {
    value: 'EUR',
    label: '€, EUR'
  },
  language: {
    value: defaultLanguage !== "ru" ? "en" : defaultLanguage,
    label: defaultLanguage !== "ru" ? "English" : "Русский"
  },
  popularPlace: {
    data: null,
    loading: false
  },
  roadCost: {
    data: null,
    loading: false,
    error: null,
  },
  cardInfo: {
    data: null,
    loading: false,
    error: null,
  },
  carTypes: {
    data: null,
    loading: false,
    error: null,
  },
  cars: {
    data: null,
    loading: false,
    error: null,
  },
  lookupData: {},
  lookupDataMode: false,
  selectData: {},
  selectDataMode: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_INFO:
      return {
        ...state,
        cardInfo: {
          data: action.payload,
          loading: false,
        }
      };
    case GET_CARD_INFO_ERROR:
      return {
        ...state,
        pays: {
          error: action.payload,
          loading: false,
        }
      };
    case GET_PLACES_PRICE_SUCCESS:
      return {
        ...state,
        placePrice: {
          data: action.payload,
          loading: false,
        }
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case GET_POPULAR_PLACE:
      return {
        ...state,
        popularPlace: {
          data: action.payload,
          loading: false,
        }
      };
    case GET_ROAD_COST:
      return {
        ...state,
        roadCost: {
          data: action.payload,
          loading: false,
        }
      };
    case GET_ROAD_COST_ERROR:
      return {
        ...state,
        roadCost: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case GET_CARTYPES_ERROR:
      return {
        ...state,
        carTypes: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case GET_CARS_ERROR:
      return {
        ...state,
        cars: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case CREATE_TRANSFER_SUCCESS:
      return {
        ...state,
        pays: {
          data: action.payload,
          loading: false
        }
      };
    case CREATE_TRANSFER_ERROR:
      return {
        ...state,
        pays: {
          error: action.payload,
          loading: false,
        }
      };

    case requestsActionTypes.CLEAR_ROAD_COST:
      return {
        ...state,
        roadCost: {
          data: null,
          loading: false,
          error: null,
        },
      };
    case requestsActionTypes.CLEAR_PAYS:
      return {
        ...state,
        pays: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case requestsActionTypes.SELECT_POINT_LIST:
      state.selectData.result = action.payload.result;
      state.selectData.pointCount = action.payload.pointCount;
      state.selectData.pointList = action.payload.pointList;

      return {
        ...state,
        selectDataMode: !state.selectDataMode
      }

    case requestsActionTypes.CLEAR_SELECT_DATA:
      state.selectData = {};

      return {
        ...state,
        selectDataMode: !state.selectDataMode,
      }

    case requestsActionTypes.LOOKUP_POINT_DATA:
      state.lookupData.result = action.payload.result;
      state.lookupData.pointId = action.payload.pointId;
      state.lookupData.tariffZoneId = action.payload.tariffZoneId;

      return {
        ...state,
        lookupDataMode: !state.lookupDataMode
      }

    default:
      return state
  }
}
