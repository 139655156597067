/*global google*/
import React from 'react';
import { useSelector, connect } from 'react-redux'
import {
  compose,
  withProps,
  lifecycle,
  withStateHandlers,
} from "recompose"
import {
  withGoogleMap,
  GoogleMap,
  BicyclingLayer,
  Polygon,
  OverlayView
} from "react-google-maps"
import { poligons } from './coords'
import { Marker } from './Marker'

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const reversedCoords = (coords) => coords.map( ll => {
  return { lat: ll[0], lng: ll[1] }
});

const MyMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentDidMount() {

    }
  }),
  withStateHandlers(
    () => ({activeMarker: null}), {
    handleChangeActiveMarker: () => (activeMarker) => {
      return { activeMarker }
    },
  }),
  withGoogleMap,
)((props) => {
  const {
    activeMarker,
    handleChangeActiveMarker,
    getPlacesPrice,
    placePrice,
    currency
  } = props;

  const changeActiveMarker = (e, name) => {
    if (e && (placePrice.name !== name)) {
      const marker = {
        name,
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      getPlacesPrice(name);
      handleChangeActiveMarker(marker)
    } else {
      handleChangeActiveMarker(null)
    }
  };
  React.useEffect(() => {
    if (activeMarker) {
      getPlacesPrice(activeMarker.name);
    }
  }, [currency]);
  const currentName = activeMarker && activeMarker.name;

  return (
    <GoogleMap
      defaultZoom={9.2}
      defaultCenter={new google.maps.LatLng(36.763514, 31.398401)}
    >
      {activeMarker && OverlayView.OVERLAY_MOUSE_TARGET && (
        <OverlayView
          position={new google.maps.LatLng(activeMarker.lat, activeMarker.lng)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <div className="popup-tip-anchor">
            <div className="popup-bubble-anchor">
              <div className="popup-bubble-content">
                <Marker
                  content={activeMarker}
                  handleChangeActiveMarker={handleChangeActiveMarker}
                  getPlacesPrice={getPlacesPrice}
                  list={placePrice.data}
                />
              </div>
            </div>
          </div>
        </OverlayView>
      )}
      {
        poligons.map((item, index) => (
          <div key={index}>
            <Polygon
              path={reversedCoords(item.coords)}
              onMouseOver={(e) => changeActiveMarker(e, item.name)}
              onMouseOut={() => changeActiveMarker(null)}
              options={{
                fillColor: item.color,
                fillOpacity: currentName === item.name ? 0.6 : item.opacity,
                strokeColor: item.borderColor,
                strokeOpacity: 1,
                strokeWeight: 1
              }} />
          </div>
        ))
      }
    </GoogleMap>
  );
});


export const MapComponent = (props) => {
  const googleAPILoaded = useSelector(({userInfo}) => userInfo.googleAPILoaded);

  //console.log('MapComponent: googleAPILoaded='+JSON.stringify(googleAPILoaded));

  if (googleAPILoaded) {
    return (<MyMapComponent {...props} />);
  } else {
    return (<div></div>);
  }
};
