import React from 'react'
import styles from './bookingcontroll.module.css';
import cn from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setOrderId } from '../../modules/info/actions'
import { getOrderInfo } from '../../modules/requests/actions'

import { MapComponent } from '../index'
import { CarBlock } from './CarBlock'
import { FirstView } from './FirstView'
import { PersonalInfo } from './PersonalInfo'
import { Paymant } from './Paymant'
import { Progress } from './Progress'
import { Done } from './Done';
import { TransferDetails } from './TransferDetails'
import { PersonalDetails } from './PersonalDetails'
import { Failed } from './Failed'
import { PayDetails } from './PayDetails'

const BookingControllerComponent = ({orderId, setOrderId, getOrderInfo, startPoint, endPoint, ...props}) => {
  const viewBlocks = [
    { step: 0,  block: <FirstView {...props} />    },
    { step: 1,  block: <PersonalInfo {...props} /> },
    { step: 2,  block: <Paymant {...props} />      },
    { step: 3,  block: <Progress {...props} />     },
    { step: 4,  block: <Done {...props} />         },
    { step: 5,  block: <Failed {...props} /> }
  ];

  // For order completions get information about order
  React.useEffect(() => {
    if (props.step === 4 || props.step === 5) {
      if (!orderId) {
        const localOrderId = props.pays && props.pays.data && props.pays.data.orderId;
        const reqOrderId = (props.match && props.match.params && props.match.params.id) || localOrderId;
        setOrderId(+reqOrderId);
      } else {
        const urlParams = props.location.search && new URLSearchParams(props.location.search);
        const orderTimestamp = urlParams && urlParams.get('timestamp');

        if (orderId && orderTimestamp) {
          getOrderInfo(orderId, orderTimestamp);
        }
      }
      //alert ("Order ID = " + orderId + ", Step: " + props.step + ", start point - " + JSON.stringify(startPoint));
    }
  }, [orderId]);


  return (
    <div className={cn(styles.container, {[styles.mobile_container]: props.step >= 1})}>
      <div className={styles.block}>
        <div className={styles.wrapper}>
          {
             viewBlocks.map((item) => (
               (item.step === props.step) && (item.block)
             ))
          }
        </div>
      </div>

      <div className={styles.map}>
        <div className={styles.mask} />
        {
          (props.step === 0) && (
            <MapComponent />
          )
        }
      </div>
      <div className={styles.container}>
        {
          (props.step === 0) && (
            <CarBlock />
          )
        }
      </div>

      <div className={styles.rightContainer}>
        {
          (props.step === 1 || props.step === 2 || props.step === 3 || props.step === 4 || props.step === 5) && (
            <TransferDetails {...props} />
          )
        }
        {
          (props.step === 2 || props.step === 3 || props.step === 4 || props.step === 5) && (
            <PersonalDetails />
          )
        }
        {
          (props.step === 3 || props.step === 4 || props.step === 5) && (
            <PayDetails />
          )
        }
      </div>
    </div>
  )
};

const mapStateToProps = ({ payment, userInfo }) => ({
  orderId: userInfo.orderId,
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  firstName: userInfo.firstName,
  lastName: userInfo.lastName,
  phone: userInfo.phone,
  lagguageSpace: userInfo.lagguageSpace,
  childSpace: userInfo.childSpace,
  flightNumber: userInfo.flightNumber,
  email: userInfo.email,
  descriptionTour: userInfo.descriptionTour,
  pays: payment.pays
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setOrderId,
  getOrderInfo
}, dispatch);


export const BookingController = connect(
  mapStateToProps,
  mapDispatchToProps,
) (BookingControllerComponent);