import React from 'react'
import logo from '../../assets/logo_full_white.svg'
import { Link, useHistory } from "react-router-dom";
import styles from './headerbooking.module.css'
import cn from 'classnames'
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { SelectHeader } from '../../formfields'
import telegram from '../../assets/telegram_16px.svg'
import whatsapp from '../../assets/whatsapp_16px.svg'
import viber from '../../assets/viber_16px.svg'
import {
  clearFields,
  clearPays
} from '../../modules/info/actions'
import {
  setCurrency,
  setLanguage
} from '../../modules/requests/actions';

const navigation = [
  {
    name: 'main.header.price',
    to: '/prices'
  },
  {
    name: 'main.header.cars',
    to: '/cars'
  },
  {
    name: 'main.header.about',
    to: '/about'
  },
  /*{
    name: 'main.header.documents',
    to: '/'
  },*/
  {
    name: 'main.header.contacts',
    to: '/contacts'
  },
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'USD',
    currency: '$'
  },
  {
    name: 'main.header.phone',
    secondText: 'main.header.phoneText'
  },
];

const mobileList = [
  {
    name: 'main.header.price',
    to: '/prices'
  },
  {
    name: 'main.header.cars',
    to: '/cars'
  },
  {
    name: 'main.header.about',
    to: '/about'
  },
  {
    name: 'main.header.contacts',
    to: '/contacts'
  },
];

const languages = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Русский',
    value: 'ru'
  }
];
const currencies = [
  {
    value: 'USD',
    label: '$, USD'
  },
  {
    value: 'EUR',
    label: '€, EUR'
  },
  {
    value: 'TRY',
    label: '₺, TRY'
  },
  {
    value: 'RUB',
    label: '₽, RUB'
  },
];

export const HeaderBookingComponent = ({
  match,
  headerText,
  prices,
  setCurrency: setCurrencyStore,
  currency,
  setLanguage: setStoreLanguage,
  language,
  pays,
  clearFields,
  clearPays
}) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const [activeLanguage, setLanguage] = React.useState(0);

  const history = useHistory();

  const [resetState, setResetState] = React.useState(false);
  const resetBooking = () => {
    setResetState(true);
  };
  React.useEffect(() => {
    if (resetState) {
      if (pays.data || pays.error) {
         clearFields();
         clearPays();
      } else {
         history.push('/');
      }
    }
  }, [pays, resetState]);

  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <header className={styles.header}>
      <div className={cn(styles.wrapper, { [styles.headerTextWrapper]: headerText })}>
        <img alt='logo' src={logo} className={styles.logo} onClick={resetBooking} />

        <ul className={styles.list}>
          {
            navigation.map((item, index) => (
              <li key={index}>
                {
                  item.secondText && (
                    <a href={`tel:${t(item.name)}`} className={cn(styles.link, styles.linkPhone)}>
                      <div>
                        {t(item.name)}
                        <span>
                          <a className={styles.iconsPhone} href={'tel: +90 552 614 56 69'}>
                            <img alt={'/'} src={whatsapp} />
                          </a>
                          <a className={styles.iconsPhone} href={'tel: +90 552 614 56 69'}>
                            <img alt={'/'} src={telegram} />
                          </a>
                          <a className={styles.iconsPhone} href={'tel: +90 552 614 56 69'}>
                            <img alt={'/'} src={viber} />
                          </a>
                        </span>
                      </div>
                      <div className={styles.secondText}>
                        {t(item.secondText)}
                      </div>
                    </a>
                  )
                }
                {
                  item.to && (
                    <Link
                      className={cn(styles.link, { [styles.activeLink]: item.to === match.path })}
                      to={item.to}
                    >
                      {
                        item.image && (
                          <img className={styles.image} alt="/" src={item.image} />
                        )
                      }
                      {t(item.name)}
                    </Link>
                  )
                }
                {
                  item.currency && (
                    <SelectHeader
                      value={currency}
                      onChange={setCurrencyStore}
                    />
                  )
                }
                {
                  item.value && (
                    <SelectHeader
                      value={language}
                      onChange={(e) => {
                        setStoreLanguage(e);
                        changeLanguage(e.value)
                      }}
                      content={languages}
                    />
                  )
                }
              </li>
            ))
          }
        </ul>
        <div className={styles.menu}>
          <a href={"tel: +90 552 614 56 69"} className={cn(styles.titleMobile)}>
            <div className={styles.iconsMessage}>
              {t('main.header.phone')}
              <a className={styles.iconsPhone} href={'tel: +90 552 614 56 69'}>
                <img alt={'/'} src={whatsapp} />
              </a>
              <a className={styles.iconsPhone} href={'tel: +90 552 614 56 69'}>
                <img alt={'/'} src={telegram} />
              </a>
              <a className={styles.iconsPhone} href={'tel: +90 552 614 56 69'}>
                <img alt={'/'} src={viber} />
              </a>
            </div>
            {t('main.header.phoneText')}
          </a>
          <div className={styles.menu_icon_wrapper} onClick={() => setOpenMenu(!openMenu)}>
            <div className={cn(styles.menu_icon, {[styles.menu_icon_active]: openMenu})} />
          </div>
          {
            openMenu && (
              <div className={styles.mobilemenu}>
                <ul className={styles.listMobile}>
                  {
                    mobileList.map((item, index) => (
                      <li key={index}>
                        <Link
                          className={styles.linkMobile}
                          to={item.to}
                        >
                          {t(item.name)}
                        </Link>
                      </li>
                    ))
                  }
                  <a href={`tel:${t('main.header.phone')}`} className={cn(styles.link, styles.linkPhone)}>
                    <div>{t('main.header.phone')}</div>
                    <div className={styles.secondText}>
                      {t('main.header.phoneText')}
                    </div>
                  </a>
                </ul>
                <div className={styles.buttons}>
                  {
                    languages.map((item, index) => (
                      <button
                        className={cn(styles.button, {[styles.buttonActive]: activeLanguage === index})}
                        key={index}
                        onClick={() => {
                          setLanguage(index);
                          changeLanguage(item.value)
                        }}
                      >
                        {item.label}
                      </button>
                    ))
                  }
                </div>
                <div className={styles.buttons}>
                  {
                    currencies.map((item) => (
                      <button
                        className={cn(styles.button, {[styles.buttonActive]: currency.value === item.value})}
                        key={item.value}
                        onClick={() => setCurrencyStore(item)}
                      >
                        {item.label}
                      </button>
                    ))
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className={styles.headerText}>
        {headerText}
        {
          prices && (
            <div className={styles.buttonsPrices}>
              {
                currencies.map((item, index) => (
                  <button
                    className={cn(styles.buttonPrices, {[styles.buttonActive]: currency.value === item.value})}
                    key={item.value}
                    onClick={() => setCurrencyStore(item)}
                  >
                    {item.label}
                  </button>
                ))
              }
            </div>
          )
        }
      </div>
    </header>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrency,
  setLanguage,
  clearFields,
  clearPays
}, dispatch);

const mapStateToProps = ({ payment }) => ({
  currency: payment.currency,
  language: payment.language,
  pays: payment.pays
});

export const HeaderBooking = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderBookingComponent);