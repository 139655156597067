import React from 'react'
import styles from './carsFirstBlock.module.css';
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getCarTypes } from '../../modules/requests/actions'

import person from '../../assets/options/person.svg'
import work from '../../assets/options/work-24px.svg'
import bolt from '../../assets/options/offline_bolt-24px.svg'
import wifi from '../../assets/options/wifi-24px.svg'
import accessible from '../../assets/options/accessible-24px.svg'
import baby from '../../assets/options/baby.svg'
import media from '../../assets/options/media.svg'
import bar from '../../assets/options/bar.svg'

export const CarsFirstBlockComponent = ({ getCarTypes, carTypeList }) => {
  React.useEffect(() => getCarTypes(), []);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <span className={styles.title}>
          {t("cars.premium")}
        </span>
        <p className={styles.text}>
          {t("cars.premiumText")}
        </p>
        <p className={styles.text}>
          {t("cars.premiumTextTwo")}
        </p>
      </div>
      <div className={styles.right}>
        {
          carTypeList && carTypeList.map((item) => (
            <div className={styles.block} key={item.id}>
              <span className={styles.nameCar}>{item.model}</span>
              <div className={styles.iconContainer}>
                <img className={styles.icon} alt="/" src={item.image} />
              </div>
              <div className={styles.options}>
                <div className={styles.option}>
                  <img alt={'/'} src={person} />
                  <span>{t('main.two.upToText')+' ' + item.totalCapacity + ' '+t('main.two.passengers')}</span>
                </div>
                <div className={styles.option}>
                  <img alt={'/'} src={work} />
                  <span>{t('main.two.upToText')+' ' + item.luggageCapacity + ' '+t('main.two.luggagePlaces')}</span>
                </div>
                {
                  item.options && (
                    <>
                      {
                        item.options.usb && (
                          <div className={styles.option}>
                            <img alt={'/'} src={bolt} />
                            <span>{t('main.two.infoUsb')}</span>
                          </div>
                        )
                      }
                      {
                        item.options.baby && (
                          <div className={styles.option}>
                            <img alt={'/'} src={baby} />
                            <span>{t('cars.baby')}</span>
                          </div>
                        )
                      }
                      {
                        item.options.media && (
                          <div className={styles.option}>
                            <img alt={'/'} src={media} />
                            <span>{t('cars.multimedia')}</span>
                          </div>
                        )
                      }
                      {
                        item.options.invalid && (
                          <div className={styles.option}>
                            <img alt={'/'} src={accessible} />
                            <span>{t('main.two.infoAvailable')}</span>
                          </div>
                        )
                      }
                      {
                        item.options.wifi && (
                          <div className={styles.option}>
                            <img alt={'/'} src={wifi} />
                            <span>{t('main.two.infoWifi')}</span>
                          </div>
                        )
                      }
                      {
                        item.options.bar && (
                          <div className={styles.option}>
                            <img alt={'/'} src={bar} />
                            <span>{t('cars.bar')}</span>
                          </div>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  carTypeList: userInfo.carTypeList,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getCarTypes
}, dispatch);

export const CarsFirstBlock = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarsFirstBlockComponent);