import React from 'react'
import logo from '../../assets/logo-blue.svg'
import { Link } from 'react-router-dom'
import styles from './mainfooter.module.css';
import { Wrapper } from "../Wrapper/index";
import { useTranslation } from "react-i18next";

import twitter from '../../assets/Icons/twitter.svg'
import instagram from '../../assets/Icons/instagram.svg'
import facebook from '../../assets/Icons/facebook.svg'
import facebookMessenger from '../../assets/Icons/facebook-messenger.svg'

const socials = [
  {
    img: facebook,
    link: 'https://www.facebook.com/ATM-VIP-Transfer-111421627251195/'
  },
  {
    img: instagram,
    link: 'https://www.instagram.com/atm_vip_transfer/'
  },
  {
    img: facebookMessenger,
    link: 'https://www.facebook.com/ATM-VIP-Transfer-111421627251195/'
  },
];

const content = [
  [
    {
      name: 'main.header.price',
      to: '/prices'
    },
    {
      name: 'main.header.cars',
      to: '/cars'
    },
  ],
  [
    {
      name: 'main.header.about',
      to: '/about'
    },
    {
      name: 'main.header.contacts',
      to: '/contacts'
    },
  ],
];



export const MainFooter = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Wrapper>
        <footer className={styles.footer}>
          <div className={styles.left}>
            <img alt="/" src={logo} />
            <span className={styles.name}>
              24-7 transfer.com
            </span>
            <span className={styles.info}>
                {t("footer.reserved")}
            </span>
          </div>
          <div className={styles.list}>
            {
              content.map((item, index) => (
                <div className={styles.block} key={index}>
                  {
                    item.map((link, linkIndex) => (
                      <Link to={link.to} className={styles.link} key={linkIndex}>
                        {t(link.name)}
                      </Link>
                    ))
                  }
                </div>
              ))
            }
          </div>
          <div className={styles.right}>
            {
              socials.map((item, index) => (
                <a href={item.link} className={styles.icon} key={index}>
                  <img alt="/" src={item.img} />
                </a>
              ))
            }
          </div>
        </footer>
      </Wrapper>
    </div>
  )
};