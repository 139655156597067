import React from "react"
import styles from './textarea.module.css'
import cn from 'classnames'

export const TextArea = ({ label = 'Label', rows = 1, onChange, value }) => (
  <div className={styles.form__group}>
    <textarea
      className={styles.form__field}
      placeholder={label}
      rows={rows}
      onChange={onChange}
      value={value}
    />
    <label
      htmlFor="message"
      className={cn(styles.form__label, styles.textarea__label)}
    >
      {label}
    </label>
  </div>
);
