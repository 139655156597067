import React from 'react'
import cn from 'classnames'
import { useHistory } from "react-router-dom";
import styles from './bookingcontroll.module.css';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import {
  clearFields,
} from '../../modules/info/actions';
import { Modal } from '../../components/Modal';
import { TransferStore, IndividualStore } from '../SearchBlock';


export const FirstViewComponent = ({
  step, setStep,
  pays,

  clearFields,
  roadCost,
}) => {
  const { t } = useTranslation();

  const isIndividual = roadCost.data && roadCost.data.error;
  const [tab, setTab] = React.useState(isIndividual ? 1: 0);

  const history = useHistory();
  React.useEffect(() => {
    if (isIndividual) {
      if (pays.data) {
        setStep(4);
      }
      if (pays.error) {
        setStep(5);
      }
    }
  }, [pays]);

  const [isOpenModal, setOpenModal] = React.useState(true);

  return (
    <div>
      <h1 className={styles.title}>
        {t("transferDetails.title")}
      </h1>
      {
        isOpenModal &&
          <Modal
            onClose={() => setOpenModal(false)}
            isOpen={isOpenModal}
          >
          {
            (tab === 0) ? (
              <div>
                <div className={styles.titleModal}>Set transfer details</div>
                <div className={styles.dividerModal} />
                <div className={styles.modalContent}>
                  <TransferStore history={history} onTransferProceed={() => setStep(1)} />
                  {
                    isIndividual && (
                      <div className={styles.individual}>
                        Looks like you are planning a trip somewhere outside our work area.
                        We recommend you <button onClick={() => setTab(1)}>to book an individual tour</button>
                      </div>
                    )
                  }
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.titleModal}>Book an Individual Tour</div>
                <div className={styles.dividerModal} />
                <div className={styles.modalContent}>
                  <IndividualStore history={history} main />
                  <div className={styles.individual}>
                    You can change your route and try <button onClick={() => setTab(0)}>to book a standard transfer</button>
                  </div>
                </div>
              </div>
            )
          }  
          </Modal>
      }
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  roadCost: payment.roadCost,
  pays: payment.pays,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFields,
}, dispatch);

export const FirstView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirstViewComponent);