import React from 'react'
import styles from './right.module.css';
import cn from 'classnames'
import { MapComponent } from '../Map'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { isValidPoint } from '../utils'
import { setOrderAmount, setOrderCurrency } from '../../modules/info/actions'
import { getRoadCost } from '../../modules/requests/actions'
import { bindActionCreators } from 'redux'

const currencies = {
  USD: '$',
  EUR: '€',
  TRY: '₺',
  RUB: '₽',
};

export const TransferDetailsComponent = ({
  step,
  setStep,
  currency,

  startPoint,
  endPoint,
  startDate,
  endDate,
  orderAmount,
  orderCurrency,
  roadCost,
  getRoadCost,
  setOrderAmount,
  setOrderCurrency,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (step !== 4 && step !== 5) {
      if (isValidPoint(startPoint) && isValidPoint(endPoint)) {
        getRoadCost(startPoint, endPoint)
      }
    }
  }, [startPoint, endPoint, currency]);

  const foundVariants = roadCost.data && !roadCost.error && roadCost.data.length && roadCost.data[0].transferVariants;
  const activeVariant = foundVariants ? foundVariants[0] : null;

  React.useEffect(() => {
    if (activeVariant) {
      setOrderAmount(activeVariant.cost);
      setOrderCurrency(activeVariant.currency);
    }
  }, [activeVariant]);


  return (
    <div className={styles.transferDetails}>
      <div className={styles.left}>
        <div className={styles.title}>
          {t("transferDetails.title")}
          {
            step === 2 && (
              <span className={styles.editButton} onClick={() => setStep(1)}>{t("editField")}</span>
            )
          }
        </div>
        <div className={styles.content}>
          <div className={styles.greyText}>
            {t("main.searchBlock.pickUpLocation")}
          </div>
          <div className={styles.blackText}>
            {startPoint.name}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.greyText}>
            {t("main.searchBlock.dropOffLocation")}
          </div>
          <div className={styles.blackText}>
            {endPoint.name}
          </div>
        </div>
        <div className={styles.containerDate}>
          {
            startDate && (
              <div className={cn(styles.content, styles.smallContent)}>
                <div className={styles.greyText}>
                  {t("main.searchBlock.date")}
                </div>
                <div className={styles.blackText}>
                  {moment(startDate).format('DD MMMM')} <span>{moment(startDate).format('dddd')}</span>
                </div>
              </div>
            )
          }
          {
            startDate && (
              <div className={cn(styles.content, styles.smallContent)}>
                <div className={styles.greyText}>
                  {t("main.searchBlock.time")}
                </div>
                <div className={styles.blackText}>
                  {moment(startDate).format('HH:mm')} <span>GMT{moment(startDate).format('Z')}</span>
                </div>
              </div>
            )
          }
          {
            endDate && (
              <div className={cn(styles.content, styles.smallContent)}>
                <div className={styles.greyText}>
                  {t("main.searchBlock.returnDate")}
                </div>
                <div className={styles.blackText}>
                  {moment(endDate).format('DD MMMM')} <span>{moment(endDate).format('dddd')}</span>
                </div>
              </div>
            )
          }
          {
            endDate && (
              <div className={cn(styles.content, styles.smallContent)}>
                <div className={styles.greyText}>
                  {t("main.searchBlock.returnTime")}
                </div>
                <div className={styles.blackText}>
                  {moment(endDate).format('HH:mm')} <span>GMT{moment(endDate).format('Z')}</span>
                </div>
              </div>
            )
          }
        </div>

        {
          activeVariant && activeVariant.carTypes && (
            <div>
              <div className={styles.car}>
              {
                activeVariant.carTypes.map((item) => (
                  item.image && (
                    <div className={styles.carTransferImg}>
                      <img alt={"/"} src={item.image} />
                    </div>
                  )
                ))
              }
              </div>

              {
                activeVariant.carTypes.length > 1 && (
                  <div className={styles.carTextBlock}>
                    <div className={styles.carText}>
                      {t("transferDetails.price")}
                    </div>
                  </div>
                )
              }

              <div className={styles.price}>
                <div className={styles.count}>
                  {currencies[orderCurrency]} {orderAmount}
                </div>

                <div className={styles.priceText}>
                  {t("transferDetails.totalTransfer")} <br />
                  {t("transferDetails.cost")}
                </div>
              </div>
            </div>
          )
        }
      </div>

      <div className={styles.right}>
        <MapComponent />
      </div>
    </div>
  )
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setOrderAmount,
  setOrderCurrency,
  getRoadCost
}, dispatch);

const mapStateToProps = ({ userInfo, payment }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  startDate: userInfo.startDate,
  endDate: userInfo.endDate,
  passengers: userInfo.passengers,
  orderAmount: userInfo.orderAmount,
  orderCurrency: userInfo.orderCurrency,
  roadCost: payment.roadCost,
  currency: payment.currency,
});

export const TransferDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferDetailsComponent);