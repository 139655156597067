import React from 'react'
import {
  HeaderMain,
  SearchBlock,
  MainTwo,
  Wrapper,
  MainFooter,
} from '../../components/index'
import background from '../../assets/bg.jpg'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { clearFields, setGoogleAPILoaded } from '../../modules/info/actions'
import { getGoogleMapsKey } from '../../modules/requests/actions'

import styles from './styles.module.css'
import map from '../../assets/MainBlock/mdi_map.svg'
import insert_invitation from '../../assets/MainBlock/insert_invitation-24px.svg'
import flight_land from '../../assets/MainBlock/flight_land-24px.svg'
import security from '../../assets/MainBlock/security-24px.svg'
import { useTranslation } from "react-i18next";

const content = [
  {
    id: 1,
    img: map,
    title: 'main.blockInfo.titleBlock1',
    text: 'main.blockInfo.textBlock1'
  },
  {
    id: 2,
    img: insert_invitation,
    title: 'main.blockInfo.titleBlock2',
    text: 'main.blockInfo.textBlock2'
  },
  {
    id: 3,
    img: flight_land,
    title: 'main.blockInfo.titleBlock3',
    text: 'main.blockInfo.textBlock3'
  },
  {
    id: 4,
    img: security,
    title: 'main.blockInfo.titleBlock4',
    text: 'main.blockInfo.textBlock4'
  },
];

export const MainPageContainer = (props) => {
  /*const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };*/

  const { t } = useTranslation();

  const [mainPageState, setMainPageState] = React.useState(0);
  const [googleState, setGoogleState] = React.useState('');

  // Cleanup previous data and Connect to Google Maps
  React.useEffect(() => {
    //console.log('-> mainPageState='+mainPageState+'; googleMapsKey='+props.googleMapsKey+'; googleState='+googleState);
    if (mainPageState === 0) {
      props.clearFields();
      setMainPageState(1);
    } else {
      if (googleState === '') {

        if (props.googleMapsKey === null || props.googleMapsKey === undefined) {
          props.getGoogleMapsKey();
        } else if (props.googleMapsKey.length) {
          const script = document.createElement("script");

          script.type="text/javascript";
          script.src="https://maps.googleapis.com/maps/api/js?key="+props.googleMapsKey+"&libraries=places,establishment,geometry,drawing";
          script.addEventListener("load", () => { console.log('script loaded'); setGoogleState('complete')});
          script.addEventListener("error", () => { console.log('script load error'); setGoogleState('error')});

          document.body.appendChild(script);
          setGoogleState('loading');
        }
      } else if (googleState === 'complete') {
        props.setGoogleAPILoaded(true);
      }
    }
  }, [mainPageState, props.googleMapsKey, googleState]);

  return (
    <div className={styles["main-page"]}>
      <HeaderMain />
      <div className={styles["main-page-background"]}>
        <img src={background} />
      </div>
      <SearchBlock {...props} />
      <Wrapper>
        <MainTwo />
      </Wrapper>
      {/*<MainThree />
      <Wrapper>
        <MainMaps {...props} />
      </Wrapper>
      <Wrapper>
        <MainInfo />
      </Wrapper>*/}
      <Wrapper>
        <div className={styles.title}>
          {t("main.blockInfo.title")}
        </div>
        <div className={styles.content}>
          {
            content.map((item) => (
              <div className={styles.block} key={item.id}>
                <img alt={"/"} src={item.img} />
                <div className={styles.blockTitle}>
                  {t(item.title)}
                </div>
                <div className={styles.text}>
                  {t(item.text)}
                </div>
              </div>
            ))
          }
        </div>
      </Wrapper>
      <MainFooter />
    </div>
  );
};


const mapStateToProps = ({ userInfo }) => ({
  googleMapsKey: userInfo.googleMapsKey
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFields,
  getGoogleMapsKey,
  setGoogleAPILoaded,
}, dispatch);

export const MainPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainPageContainer);
