import React from 'react'
import cn from 'classnames'
import { useHistory } from "react-router-dom";
import styles from './search.module.css';
import PhoneInput from 'react-phone-input-2'
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from "date-fns/locale/ru"
import en from "date-fns/locale/en-US"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import Processing from '../../assets/Processing.png';
import { isValidPoint } from '../utils'
import { AddressField, CheckBox, TextArea, TextField } from '../../formfields'
import { Modal } from '../Modal'
import { PassengerSelect } from './PassengerSelect';
import {
  reqStartPoint,
  reqEndPoint,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
  setTourDate,
  setPassengers,
  setDescriptionTour,
  setStartPoint,
  setEndPoint,
  clearFields,
  setEmail,
  setPhone,
  setFirstName,
  setLugguageSpace,
} from '../../modules/info/actions'
import "react-datepicker/dist/react-datepicker.css";
import 'react-google-places-autocomplete/dist/assets/index.css';
import {
  setCurrency,
  createTransfer,
  getRoadCost
} from '../../modules/requests/actions';
import moment from 'moment';

const tabsContent = [
  'main.searchBlock.getTransfer',
  'main.searchBlock.individualTour'
];

registerLocale("ru", ru);
registerLocale("en", en);

const dateFormat = {
  en: 'd MMMM yyyy',
  ru: 'd MMMM yyyy'
};

export const Individual = ({
  language,
  history,
  pays,

  passengers,
  descriptionTour,
  setDescriptionTour,
  main,
  startPoint,
  endPoint,
  setStartPoint,
  setEndPoint,
  reqStartPoint,
  reqEndPoint,
  setEmail,
  setFirstName,
  email,
  firstName,
  createTransfer,
  setStartDate,
  startDate,
  phone,
  setPhone,
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = React.useState(false);

  const refTour = React.createRef();
  const CustomDateInput = React.forwardRef(({ onClick, value }, ref) => (
    <div onClick={onClick} ref={ref}>
      <TextField
        required
        label={t('main.searchBlock.tourDate')}
        defaultValue={value}
      />
    </div>
  ));

  const handlerPoint = (props, type) => {
    if (type === 'start') {
      reqStartPoint(props)
    } else if (type === 'end') {
      reqEndPoint(props)
    }
  };
  const isValid = isValidPoint(startPoint) && isValidPoint(endPoint) &&
                  firstName && passengers && startDate && phone;

  React.useEffect(() => {
    if (!startPoint.name)
      setStartPoint({
        //id: null,
        name: '',
        //latitude: null,
        //longitude: null,
        //tariffZoneId: null
      });
  }, [startPoint.name]);

  React.useEffect(() => {
    if (!startPoint.name)
      setEndPoint({
        //id: null,
        name: '',
        //latitude: null,
        //longitude: null,
        //tariffZoneId: null
      });
  }, [endPoint.name]);

  React.useEffect(() => {
    if (history.location.pathname === '/') {
      if (pays.data) {
        history.push('/booking/success')
      }
      if (pays.error) {
        history.push('/booking/fail')
      }
    }
  }, [pays]);

  return (
    <div>
      {
        main && (
          <div className={styles.form}>
            <div className={styles.firstInput}>
              <AddressField
                fieldnumber={150}
                label={t('main.searchBlock.pickUpLocation')}
                value={startPoint.name}
                fillCoords={(props) => handlerPoint(props, 'start')}
                fillDescx={(e) => {
                  const newPoint = {...startPoint};
                  newPoint.name = e.target.value;
                  setStartPoint(newPoint);
                }}
                setPointData={(item) => setStartPoint({
                  id: item.id,
                  name: item.description,
                  latitude: item.latitude,
                  longitude: item.longitude
                })}
              />
            </div>
            <div className={styles.secondInput}>
              <AddressField
                fieldnumber={151}
                label={t('main.searchBlock.dropOffLocation')}
                value={endPoint.name}
                fillCoords={(props) => handlerPoint(props, 'end')}
                fillDescx={(e) => {
                  const newPoint = {...endPoint};
                  newPoint.name = e.target.value;
                  setEndPoint(newPoint);
                }}
                setPointData={(item) => setEndPoint({
                  id: item.id,
                  name: item.description,
                  latitude: item.latitude,
                  longitude: item.longitude,
                  tariffZoneId: item.tariffZoneId
                })}
              />
            </div>
          </div>
        )
      }

      <div className={styles.individualInfo}>
        <div className={cn(styles.wrapperField, styles.smallInput)}>
          <TextField
            label={t('main.searchBlock.yourName')}
            required
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </div>
        <div className={cn(styles.smallInput)}>
          <PhoneInput
            required
            country={'ru'}
            value={phone}
            onChange={(e) => setPhone(e)}
          />
        </div>
      </div>

      <div className={cn(styles.wrapperField)}>
        <TextField
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className={styles.individualInfo}>
        <div className={cn(styles.wrapperField, styles.smallInput)}>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            customInput={<CustomDateInput ref={refTour} />}
            minDate={new Date()}
            dateFormat={dateFormat[language.value]}
            locale={language.value}
          />
        </div>
        <div className={cn(styles.smallInput)}>
          <PassengerSelect />
        </div>
      </div>
      <div className={styles.textareaWrapper}>
        <TextArea
          label={t('main.searchBlock.description')}
          value={descriptionTour}
          onChange={(e) => setDescriptionTour(e.target.value)}
        />
      </div>
      <div className={styles.currencyBlock}>
        <button
          className={cn(styles.button, styles.buttonSubmit, { [styles.disabledButton]: !isValid })}
          onClick={() => {
            createTransfer(true);
            setModal(true);
          }}
          disabled={!isValid}
        >
          {t('main.searchBlock.submit')}
        </button>
      </div>
      {
        modal && (
          <Modal
            onClose={() => setModal(false)}
          >
            <div className={styles.modalContainer}>
              {
                pays.loading && (
                  <div className={styles.progress}>
                    <img alt="/" src={Processing} className={'loading'} />
                  </div>
                )
              }
              {
                pays.data && (
                  <div className={styles.textModal}>
                    {t("main.searchBlock.success")}
                  </div>
                )
              }
              {
                pays.error && (
                  <div className={styles.textModal}>
                    {t("main.searchBlock.error")}
                  </div>
                )
              }
            </div>
          </Modal>
        )
      }
    </div>
  )
}; /* end of Individual */

export const Transfer = ({
  language,
  history,
  onTransferProceed,

  startPoint,
  reqStartPoint,
  setStartPoint,
  endPoint,
  reqEndPoint,
  setEndPoint,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
  passengers,
  childSpace,
  lagguageSpace,
  setLugguageSpace,
  roadCost
}) => {
  const { t } = useTranslation();
  const [isTransferBack, setTransferBack] = React.useState(false);

  const [directTime, setDirectTime] = React.useState(startDate ? moment(startDate).format('HH:mm') : '');
  const [returnTime, setReturnTime] = React.useState(endDate ? moment(endDate).format('HH:mm') : '');

  React.useEffect(() => {
    if (!isTransferBack && endDate) {
      setTransferBack(true);
    }
  });

  const setLagguage = (value) => {
    if (!lagguageSpace && value === -1) {
      return;
    }
    setLugguageSpace(lagguageSpace + value)
  };

  const closeEndTransfer = (flag) => {
    setTransferBack(flag);
    setEndDate(null);
  };

  const ref = React.createRef();
  const CustomDateInput = React.forwardRef(({ onClick, value }, ref) => (
    <div onClick={onClick} ref={ref}>
      <TextField
        label={t('main.searchBlock.date')}
        required
        defaultValue={value}
      />
    </div>
  ));
  React.useEffect(() => {
    if (startDate) {
      setDirectTime(moment(startDate).format('HH:mm'));
    } else {
      setDirectTime('');
    }
  }, [startDate]);
  React.useEffect(() => {
    if (directTime !== '') {
      setStartTime(directTime);
    }
  }, [directTime]);

  const refEnd = React.createRef();
  const CustomDateInputEnd = React.forwardRef(({ onClick, value }, ref) => (
    <div onClick={onClick} ref={ref}>
      <TextField
        label={t('main.searchBlock.returnDate')}
        defaultValue={value}
      />
    </div>
  ));
  React.useEffect(() => {
    if (endDate) {
      setReturnTime(moment(endDate).format('HH:mm'));
    } else {
      setTransferBack(false);
      setReturnTime('');
    }
  }, [endDate]);
  React.useEffect(() => {
    if (returnTime !== '') {
      setEndTime(returnTime);
    }
  }, [returnTime]);


  const handlerPoint = (props, type) => {
    if (type === 'start') {
      reqStartPoint(props)
    } else if (type === 'end') {
      reqEndPoint(props)
    }
  };

  React.useEffect(() => {
    if (!startPoint.name)
    setStartPoint({
      //id: null,
      name: '',
      //latitude: null,
      //longitude: null,
      //tariffZoneId: null
    });
  }, [startPoint.name]);

  React.useEffect(() => {
    if (!startPoint.name)
      setEndPoint({
        //id: null,
        name: '',
        //latitude: null,
        //longitude: null,
        //tariffZoneId: null
      });
  }, [endPoint.name]);

  const datesError = moment(endDate).isBefore(startDate);

  const isNext = (roadCost && roadCost.data && roadCost.data[0] && roadCost.data[0].basicCost) && 
                 isValidPoint(startPoint) && isValidPoint(endPoint) &&
                 startDate && !datesError &&
                 passengers;

  return (
    <div>
      <div className={styles.form}>
        <div className={styles.firstInput}>
          <AddressField
            fieldnumber={140}
            label={t('main.searchBlock.pickUpLocation')}
            value={startPoint.name}
            fillCoords={(props) => handlerPoint(props, 'start')}
            fillDescx={(e) => {
              const newPoint = {...startPoint};
              newPoint.name = e.target.value;
              setStartPoint(newPoint);
            }}
            setPointData={(item) => setStartPoint({
              id: item.id,
              name: item.description,
              latitude: item.latitude,
              longitude: item.longitude,
              tariffZoneId: item.tariffZoneId
            })}
            required
          />
        </div>
        <div className={styles.secondInput}>
          <AddressField
            fieldnumber={141}
            label={t('main.searchBlock.dropOffLocation')} 
            value={endPoint.name}
            fillCoords={(props) => handlerPoint(props, 'end')}
            fillDescx={(e) => {
              const newPoint = {...endPoint};
              newPoint.name = e.target.value;
              setEndPoint(newPoint);
            }}
            setPointData={(item) => setEndPoint({
              id: item.id,
              name: item.description,
              latitude: item.latitude,
              longitude: item.longitude,
              tariffZoneId: item.tariffZoneId
            })}
            required
          />
        </div>
      </div>
      <div className={cn(styles.formDate, { [styles.formDateError]: datesError })}>
        <div className={styles.date}>
          <DatePicker
            selected={startDate}
            onChange={date => {
              setEndDate(null);
              if (moment().isSame(date, 'date')) {
                setStartDate(moment().add(3, 'hour').toDate());
              } else {
                setStartDate(date);
              }
            }}
            customInput={<CustomDateInput ref={ref} />}
            minDate={new Date()}
            dateFormat={dateFormat[language.value]}
            locale={language.value}
          />
        </div>
        <div className={styles.dividerTime} />
        <div className={styles.time}>
          <TextField
            label={t("main.searchBlock.time")}
            required
            onChange={(e) => setDirectTime(e.target.value)}
            value={directTime}
            type="time"
          />
        </div>
      </div>
      <div className={styles.formCheckbox}>
        <CheckBox
          text={t('main.searchBlock.needReturnTransfer')}
          checked={isTransferBack}
          onChange={closeEndTransfer}
        />
      </div>
      {
        isTransferBack && (
          <div className={cn(styles.formDate, { [styles.formDateError]: datesError })}>
            <div className={styles.date}>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                customInput={<CustomDateInputEnd ref={refEnd} />}
                minDate={startDate ? startDate : new Date()}
                dateFormat={dateFormat[language.value]}
                locale={language.value}
              />
            </div>
            <div className={styles.dividerTime} />
            <div className={styles.time}>
              <TextField
                label={t("main.searchBlock.time")}
                required
                onChange={(e) => setReturnTime(e.target.value)}
                value={returnTime}
                type="time"
              />
            </div>
          </div>
        )
      }
      <PassengerSelect />
      <button className={cn(styles.button, { [styles.disabledButton]: !isNext })} onClick={onTransferProceed} disabled={!isNext}>
        {t('main.searchBlock.search')}
      </button>
    </div>
  )
}; /* end of Transfer */


export const SearchBlockContainer = (props) => {
  const { t } = useTranslation();

  const [tab, setTab] = React.useState(0);
  const history = useHistory();

  React.useEffect(() => {
    // Try to get tariff information only for standard transfers
    if (tab === 0) {
      let checkOnlyFlag = true;

      if (isValidPoint(props.startPoint) && isValidPoint(props.endPoint)) {
        props.getRoadCost(props.startPoint, props.endPoint, checkOnlyFlag)
      }
    }
  }, [props.startPoint, props.endPoint]);

  React.useEffect(() => {
    // Try to get tariff information only for standard transfers
    if (tab === 0) {
      if (isValidPoint(props.startPoint) && isValidPoint(props.endPoint)) {
        props.getRoadCost(props.startPoint, props.endPoint)
      }
    }
  }, [props.passengers, props.childSpace, props.lagguageSpace]);


  const isIndividual = props.roadCost.data && props.roadCost.data.error;
  React.useEffect(() => {
    if (isIndividual) {
      setTab(1)
    }
  }, [isIndividual]);

  const mapTab = [
    <Transfer {...props} onTransferProceed={() => history.push('/booking')} />,
    <Individual {...props} main />
  ];
  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>
          {t('main.searchBlock.title')}
        </h1>
        {/*<div className={cn(styles.titleMobile)}>
          {t('main.searchBlock.title')} <br />
          {t('main.header.phone')} <br />
          {t('main.header.phoneText')}
        </div>*/}
      </div>

      <div className={styles.block}>
        <div className={styles.wrapper}>
          <div className={styles.tabs}>
            {
              tabsContent.map((item, index) => (
                <div
                  key={index}
                  className={cn(styles.tab, {[styles.activeTab]: index === tab})}
                  onClick={() => setTab(index)}
                >
                  {t(item)}
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.wrapper}>
          {mapTab[tab]}
          <div className={styles.clearContainer}>
            <div className={styles.clear} onClick={props.clearFields}> {t("clearFields")}</div>
          </div>
        </div>
      </div>
    </div>
  )
}; /* end of SearchBlockContainer */



/// SearchBlock containers
const mapStateToProps = ({ userInfo, payment }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  startDate: userInfo.startDate,
  endDate: userInfo.endDate,
  phone: userInfo.phone,
  tourDate: userInfo.tourDate,
  passengers: userInfo.passengers,
  descriptionTour: userInfo.descriptionTour,
  currency: payment.currency,
  email: userInfo.email,
  firstName: userInfo.firstName,
  pays: payment.pays,
  language: payment.language,
  childSpace: userInfo.childSpace,
  lagguageSpace: userInfo.lagguageSpace,
  roadCost: payment.roadCost,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  reqStartPoint,
  reqEndPoint,
  setStartPoint,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
  setTourDate,
  setPassengers,
  setDescriptionTour,
  setCurrency,
  setEndPoint,
  clearFields,
  setEmail,
  setFirstName,
  createTransfer,
  setLugguageSpace,
  getRoadCost,
  setPhone
}, dispatch);

export const SearchBlock = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBlockContainer);

export const TransferStore = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transfer);

export const IndividualStore = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Individual);
