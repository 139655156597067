import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {
  SET_TRANSFER_CLASS,
  SET_DESCRIPTION_TOUR,
  SET_PASSENGERS,
  SET_TOUR_DATE,
  SET_CHILD_SPACE,
  SET_FIRST_NAME,
  SET_FLIGHT_NUMBER,
  SET_LAGGUAGE_SPACE,
  SET_LAST_NAME,
  SET_PHONE,
  SET_EMAIL,
} from './constants'
import { infoActionTypes } from '../info/constants'

import { requestsActionTypes } from '../requests/constants'

export const setOrderId = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_ORDER_ID,
      payload,
    });
  }
};

export const setOrderAmount = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_ORDER_AMT,
      payload
    });
  }
};

export const setOrderCurrency = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_ORDER_CURR,
      payload
    });
  }
};

export const setEmail = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_EMAIL,
      payload,
    });
  }
};

export const setChildSpace = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_CHILD_SPACE,
      payload,
    });
  }
};

export const setFirstName = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_FIRST_NAME,
      payload,
    });
  }
};

export const setFlightNumber = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_FLIGHT_NUMBER,
      payload,
    });
  }
};

export const setLugguageSpace = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_LAGGUAGE_SPACE,
      payload,
    });
  }
};

export const setLastName = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_LAST_NAME,
      payload,
    });
  }
};

export const setPhone = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_PHONE,
      payload,
    });
  }
};

export const setTransferClass = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_TRANSFER_CLASS,
      payload,
    });
  }
};

export const setDescriptionTour = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_DESCRIPTION_TOUR,
      payload,
    });
  }
};

export const setPassengers = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_PASSENGERS,
      payload,
    });
  }
};

export const setStartDate = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_START_DATE,
      payload,
    });
  }
};

export const setEndDate = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_END_DATE,
      payload,
    });
  }
};

export const setStartTime = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_START_TIME,
      payload,
    });
  }
};

export const setEndTime = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_END_TIME,
      payload,
    });
  }
};

export const setTourDate = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_TOUR_DATE,
      payload,
    });
  }
};

export const reqStartPoint = (props) => {
  return dispatch => {
    let payload = {
      name: props.description
    };
    geocodeByAddress(props.description)
      .then(results => getLatLng(results[0]))
      .then(({lat, lng }) => {
        payload.latitude = lat;
        payload.longitude = lng;
        dispatch(setStartPoint(payload));
      });
  }
};

export const reqEndPoint = (props) => {
  return dispatch => {
    let payload = {
      name: props.description
    };
    geocodeByAddress(props.description)
      .then(results => getLatLng(results[0]))
      .then(({lat, lng}) => {
        payload.latitude = lat;
        payload.longitude = lng;
        dispatch(setEndPoint(payload));
      });
  }
};

export const setStartPoint = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_START_POINT,
      payload,
    });
  }
};
export const setEndPoint = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_END_POINT,
      payload,
    });
  }
};

export const setDocumentName = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_DOCUMENT_NAME,
      payload,
    });
  }
};

export const setGoogleAPILoaded = (loadFlag) => {
  let payload = {
    loadFlag: loadFlag
  }

  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_GOOGLEAPI_LOAD,
      payload,
    });
  }
};

export const setNeedReceipt = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_NEED_RECEIPT,
      payload,
    });
  }
};
export const setReceiptCompany = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_RECEIPT_COMPANY,
      payload,
    });
  }
};
export const setReceiptDetails = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_RECEIPT_DETAILS,
      payload,
    });
  }
};
export const setReceiptFirstName = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_RECEIPT_FIRSTNAME,
      payload,
    });
  }
};
export const setReceiptLastName = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_RECEIPT_LASTNAME,
      payload,
    });
  }
};

export const setOnlinePayment = (onlinePaymentFlag) => {
  let payload = {
    onlinePaymentFlag: onlinePaymentFlag
  }

  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_ONLINE_PAYMENT,
      payload,
    });
  }
};
export const setCardPAN = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_CARD_PAN,
      payload,
    });
  }
};
export const setCardExpDate = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_CARD_EXPDATE,
      payload,
    });
  }
};
export const setCardCVV2 = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_CARD_CVV2,
      payload,
    });
  }
};


export const clearFields = () => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.CLEAR_FIELD,
    });
    dispatch({
      type: requestsActionTypes.CLEAR_ROAD_COST,
    });
  }
};

export const clearPays = () => {
  return dispatch => {
    dispatch({
      type: requestsActionTypes.CLEAR_PAYS,
    });
  }
};
