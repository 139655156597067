import React from 'react'
import Slider from "react-slick";

import cn from 'classnames'

import one from '../../assets/cars/IMG-20191217-WA0010.jpg'
import two from '../../assets/cars/IMG-20191219-WA0000.jpg'
import three from '../../assets/cars/IMG-20191219-WA0002.jpg'
import four from '../../assets/cars/IMG-20200108-WA0010.jpg'
import five from '../../assets/cars/IMG-20200108-WA0014.jpg'
import six from '../../assets/cars/IMG-20200108-WA0011.jpg'
import arrowBack from '../../assets/Icons/arrow_back-24px.png'
import arrowForward from '../../assets/Icons/arrow_forward-24px.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './mainthree.module.css';

const sliderContent = [
  {
    title: 'Mercedes-Benz Vito',
    text: 'Трансфер премиум класса 10 пассажирских мест',
    image: one
  },
  {
    title: 'Mercedes-Benz Vito',
    text: 'Трансфер премиум класса 10 пассажирских мест',
    image: two
  },
  {
    title: 'Mercedes-Benz Vito',
    text: 'Трансфер премиум класса 10 пассажирских мест',
    image: three
  },
  {
    title: 'Mercedes-Benz Vito',
    text: 'Трансфер премиум класса 10 пассажирских мест',
    image: four
  },
  {
    title: 'Mercedes-Benz Vito',
    text: 'Трансфер премиум класса 10 пассажирских мест',
    image: five
  },
  {
    title: 'Mercedes-Benz Vito',
    text: 'Трансфер премиум класса 10 пассажирских мест',
    image: six
  },
];

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      alt="/"
      src={arrowForward}
      className={cn(className, styles.rightArrow)}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      alt="/"
      src={arrowBack}
      className={cn(className, styles.leftArrow)}
      style={{
        ...style,
        zIndex: 99,
      }}
      onClick={onClick}
    />
  );
}

export const MainThree = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const settings = {
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
    afterChange: (index) => setCurrentSlide(index),
    appendDots: dots => {
      return (
        <div style={{
          bottom: -10,
        }}>
          <ul style={{ padding: 0 }}>
            {dots.map((item, index) => {
              return (
                <li className={styles.sizePag} key={index}>{item.props.children}</li>
              );
            })}
          </ul>
        </div>
      )
    },
    customPaging: index => {
      return (
        <div className={cn(styles.pag, {[styles.pagActive]: currentSlide === index})} />
      )
    },
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Slider {...settings}>
          {
            sliderContent.map((item, index) => (
              <div key={index} className={styles.slide}>
                <img alt="/" src={item.image}/>
              </div>
            ))
          }
        </Slider>
      </div>
    </div>
  )
};