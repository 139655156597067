import React from 'react';
import styles from './marker.module.css';
import cn from 'classnames'
import { useTranslation } from "react-i18next";

const currencies = {
  USD: '$',
  EUR: '€',
  TRY: '₺',
  RUB: '₽',
};

export const Marker = ({ content, handleChangeActiveMarker, list }) => {
  const { name} = content;
  const { t } = useTranslation();
  const [isAll, setAllButtons] = React.useState(false);
  const isShow = list && (list.length > 3 && !isAll);
  const handlerClose = () => {
    if (isShow) {
      setAllButtons(true)
    } else {
      handleChangeActiveMarker(null)
    }
  };
  return (
    <div className={styles.content}>
      <div className={styles.title}>
        {name}
        <div className={styles.textTitle}>
          {t('main.map.from')}
        </div>
      </div>
      <div className={styles.container}>
        {
          list && list.map((item, index) => (
            <div key={index} className={cn(styles.place, { [styles.disabled]: isShow && (index > 3) })}>
              <div className={styles.city}>
                {item.name}
              </div>
              <div className={styles.price}>
                {currencies[item.currency || 'USD']}{item.basicCost}
              </div>
            </div>
          ))
        }
        {/*<div className={styles.button} onClick={handlerClose}>
          {isShow ? `Show more (${list.length - 3} places)` : 'Close'}
        </div>*/}
      </div>
    </div>
  );
};
