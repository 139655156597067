import React from 'react'
import cancel from '../../assets/modal/Cancel.svg'
import styles from './modal.module.css'

export const Modal = ({ onClose, children }) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={styles.container}>
      <div className={styles.modal} ref={ref}>
        <img className={styles.cansel} alt={'/'} src={cancel} onClick={onClose} />
        {children}
      </div>
    </div>
  )
};
