import React from 'react'
import {
  MainFooter,
  HeaderBooking,
  Wrapper
} from '../../components'
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { clearFields, setGoogleAPILoaded } from '../../modules/info/actions'
import { getPlacesPrice,
         getGoogleMapsKey } from '../../modules/requests/actions'
import { MapComponent } from '../../components/MapPolygon'
import { MainThree } from '../../components/MainThree'
import secondBlockImage from '../../assets/about/secondBlockImage.jpg'
import fourBg from '../../assets/about/fourBg.jpg'
import shield from '../../assets/about/shield.svg'
import mbLogo from '../../assets/about/mb-logo.svg'
import childseats from '../../assets/about/childseats.svg'
import work from '../../assets/about/work-24px.svg'
import person from '../../assets/about/person.svg'
import styles from './about.module.css';

import music from '../../assets/about/musik.svg'
import drink from '../../assets/about/bar.svg'
import wifi from '../../assets/about/wifi.svg'
import offline_bolt from '../../assets/about/offline_bolt.svg'
import flight_land from '../../assets/about/flight_land.svg'
import bag from '../../assets/about/bag.svg'

const secondContent = [
  'about.textSecond1',
  'about.textSecond2',
  'about.textSecond3',
  'about.textSecond4',
];

const blockContent = [
  {
    text: 'cars.usb',
    img: offline_bolt,
  },
  {
    text: 'cars.wifi',
    img: wifi,
  },
  {
    text: 'cars.multimedia',
    img: music,
  },
  {
    text: 'cars.bar',
    img: drink,
  },
  {
    text: 'cars.tracking',
    img: flight_land,
  },
  {
    text: 'cars.service',
    img: bag,
  },
];

const placeLoacation = [
  {
    title: 'Antalya',
    text: 'Antalya, Antalya Airport ✈️',
  },
  {
    title: 'Serik',
    text: 'Serik, Belek',
  },
  {
    title: 'Side',
    text: 'Side, Karacalar',
  },
  {
    title: 'Avsallar',
    text: 'Avsallar, Okurcalar',
  },
  {
    title: 'Alanya',
    text: 'Alanya, Gazipaşa Airport ✈️,  Mahmutlar, Konaklı ',
  },
];

const contentSeven = [
  {
    title: 'about.sevenBlockContentTitle1',
    img: mbLogo,
    text: 'about.sevenBlockContentText1'
  },
  {
    title: 'about.sevenBlockContentTitle2',
    img: person,
    text: 'about.sevenBlockContentText2'
  },
  {
    title: 'about.sevenBlockContentTitle3',
    img: childseats,
    text: 'about.sevenBlockContentText3'
  },
  {
    title: 'about.sevenBlockContentTitle4',
    img: work,
    text: 'about.sevenBlockContentText4'
  },
];

export const AboutPageComponent = (props) => {
  const { t } = useTranslation();

  const [aboutPageState, setAboutPageState] = React.useState(0);
  const [googleState, setGoogleState] = React.useState('');

  // Cleanup previous data and Connect to Google Maps
  React.useEffect(() => {
    //console.log('-> aboutPageState='+aboutPageState+'; googleMapsKey='+props.googleMapsKey+'; googleState='+googleState);
    if (aboutPageState === 0) {

      props.clearFields();
      setAboutPageState(1);
    } else {
      if (googleState === '') {

        if (props.googleMapsKey === null || props.googleMapsKey === undefined) {
          props.getGoogleMapsKey();
        } else if (props.googleMapsKey.length) {
          const script = document.createElement("script");

          script.type="text/javascript";
          script.src="https://maps.googleapis.com/maps/api/js?key="+props.googleMapsKey+"&libraries=places,establishment,geometry,drawing";
          script.addEventListener("load", () => { console.log('script loaded'); setGoogleState('complete')});
          script.addEventListener("error", () => { console.log('script load error'); setGoogleState('error')});

          document.body.appendChild(script);
          setGoogleState('loading');
        }
      } else if (googleState === 'complete') {
        props.setGoogleAPILoaded(true);
      }
    }
  }, [aboutPageState, props.googleMapsKey, googleState]);

  return (
    <div>
      <HeaderBooking
        {...props}
        headerText={t("about.title")}
      />
      <div className={styles.firstBlock}>
        <Wrapper>
          <div className={styles.firstBlockWrapper}>
            <div className={styles.firstTitle}>
              <span>{t("about.firstTitle")}</span>
            </div>
            <div className={styles.firstText}>{t("about.firstText")}</div>
          </div>
        </Wrapper>
      </div>

      <Wrapper>
        <div className={styles.secondBlock}>
          <div className={styles.secondLeft}>
            <img alt="/" src={secondBlockImage} />
            <div className={styles.secondTitle}>
              <span>{t("about.VIPTransfer")}</span>
            </div>
          </div>
          <div className={styles.secondRight}>
            {
              secondContent.map(item => (
                <div key={item} className={styles.secondRightText}>{t(item)}</div>
              ))
            }
          </div>
        </div>
      </Wrapper>


      <Wrapper>
        <div className={styles.grayBlock}>
          <div className={styles.grayBlockTitle}>{t("about.grayBlockTitle")}</div>
          <Link to="/booking" className={styles.button}>{t("about.bookTransfer")}</Link>
        </div>
      </Wrapper>

      <div className={styles.thirdBlock}>
        <Wrapper>
          <div className={styles.thirdBlockTitle}>{t("about.thirdTitle")}</div>
          <div className={styles.thirdBlockContent}>
            <div className={styles.thirdBlockTextFirst}>
              <div>{t("about.thirdTextFirst1")}</div>
              <div>{t("about.thirdTextFirst2")}</div>
            </div>
            <div className={styles.thirdBlockTextSecond}>
              <div>{t("about.thirdTextSecond1")}</div>
              <div>{t("about.thirdTextSecond2")}</div>
              <div>{t("about.thirdTextSecond3")}</div>
            </div>
          </div>
        </Wrapper>
      </div>

      <div className={styles.fourBlock}>
        <Wrapper>
          <div className={styles.fourBlockTitle}>
            <span>{t("about.fourBlockTitle")}</span>
          </div>
          <div className={styles.fourBlockText}>{t("about.fourBlockText")}</div>
          <Link to="/booking" className={styles.fourBlockLink}>{t("about.fourBlockLink")}</Link>
        </Wrapper>
        <div className={styles.fourBlockImage}>
          <img alt="/" src={fourBg} />
        </div>
      </div>

      <div className={styles.fiveBlock}>
        <Wrapper>
          <div className={styles.fiveBlockTitle}>{t("about.fiveBlockTitle")}</div>
          <div className={styles.fiveBlockWrapper}>
            <div className={styles.fiveBlockLeft}>
              <div className={styles.fiveBlockTextLight}>{t("about.fiveBlockTextLight")}</div>
              <div className={styles.fiveBlockTextBold}>{t("about.fiveBlockTextBold")}</div>
            </div>
            <div className={styles.fiveBlockRight}>
              <img alt="/" src={shield} />
              <div className={styles.fiveBlockTextBlue}>{t("about.fiveBlockTextBlue")}</div>
            </div>
          </div>
        </Wrapper>
      </div>

      <Wrapper>
        <div className={styles.sixBlock}>
          <div className={styles.sixBlockLeft}>
            <div className={styles.sixBlockText}>{t("about.sixBlockText1")}</div>
            <div className={styles.sixBlockText}>{t("about.sixBlockText2")}</div>
            <div className={styles.sixBlockText}>{t("about.sixBlockText3")}</div>
            <Link to="/prices" className={styles.sixButton}>{t("about.sixBlockButton")}</Link>
          </div>
          <div className={styles.sixBlockRight}>
            <div className={styles.map}>
              <MapComponent
                getPlacesPrice={props.getPlacesPrice}
                placePrice={props.placePrice}
                currency={props.currency}
              />
            </div>
            <div className={styles.priceList}>
              {
                placeLoacation.map((item, index) => (
                  <div className={styles.placeBlock} key={index}>
                    <div className={styles.point} />
                    <div className={styles.placeTextBlock}>
                      <div className={styles.placeTitle}>
                        {item.title}
                      </div>
                      <div className={styles.placeText}>
                        {item.text}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </Wrapper>

      <div className={styles.sevenBlock}>
        <Wrapper>
          <div className={styles.sevenBlockTitle}>
            <span>
              {t("about.sevenBlockTitle")}
            </span>
          </div>
          <div className={styles.sevenBlockContent}>
            {
              contentSeven.map(item => (
                <div key={item.title} className={styles.sevenBlockItem}>
                  <img alt="/" src={item.img} className={styles.sevenBlockImg} />
                  <div className={styles.sevenBlockContentTitle}>{t(item.title)}</div>
                  <div className={styles.sevenBlockContentText}>{t(item.text)}</div>
                </div>
              ))
            }
          </div>
        </Wrapper>
      </div>

      <div className={styles.eighthBlock}>
        <MainThree />
      </div>

      <Wrapper>
        <div className={styles.ninthBlock}>
          <div className={styles.ninthBlockLeft}>
            <div className={styles.ninthBlockLeftTextBlock}>
              <div className={styles.ninthBlockLeftText}>{t("about.ninthBlockLeftText1")}</div>
              <div className={styles.ninthBlockLeftText}>{t("about.ninthBlockLeftText2")}</div>
              <div className={styles.ninthBlockLeftText}>{t("about.ninthBlockLeftText3")}</div>
            </div>
            <div className={styles.ninthBlockLeftTextBlock}>
              <div className={styles.ninthBlockLeftText}>{t("about.ninthBlockLeftText4")}</div>
              <div className={styles.ninthBlockLeftText}>{t("about.ninthBlockLeftText5")}</div>
            </div>
          </div>
          <div className={styles.ninthRight}>
            {
              blockContent.map(item => (
                <div key={item.text} className={styles.ninthRightBlock}>
                  <img alt="/" src={item.img} />
                  <div className={styles.ninthBlockRightText}>{t(item.text)}</div>
                </div>
              ))
            }
          </div>
        </div>
      </Wrapper>

      <Wrapper>
        <div className={styles.grayBlock}>
          <div className={styles.grayBlockTitle}>{t("about.grayBlockTitle")}</div>
          <Link to="/booking" className={styles.button}>{t("about.bookTransfer")}</Link>
        </div>
      </Wrapper>

      <MainFooter />
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  googleMapsKey: userInfo.googleMapsKey,
  placePrice: payment.placePrice,
  currency: payment.currency,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFields,
  getGoogleMapsKey,
  setGoogleAPILoaded,
  getPlacesPrice,
}, dispatch);

export const AboutPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutPageComponent);
