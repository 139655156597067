import React from 'react'
import styles from './firstScreenPrice.module.css';
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { AddressField, TextField } from '../../formfields'
import { isValidPoint } from '../utils'
import { MapComponent } from '../MapPolygon'
import {
  getPlacesPrice,
  getRoadCost
} from '../../modules/requests/actions'
import {
  reqStartPoint,
  reqEndPoint,
  setStartPoint,
  setEndPoint
} from '../../modules/info/actions'

const placeLoacation = [
  {
    title: 'Antalya',
    text: 'Antalya, Antalya Airport ✈️',
  },
  {
    title: 'Serik',
    text: 'Serik, Belek',
  },
  {
    title: 'Side',
    text: 'Side, Karacalar',
  },
  {
    title: 'Avsallar',
    text: 'Avsallar, Okurcalar',
  },
  {
    title: 'Alanya',
    text: 'Alanya, Gazipaşa Airport ✈️,  Mahmutlar, Konaklı ',
  },
];

const currencies = {
  USD: '$',
  EUR: '€',
  TRY: '₺',
  RUB: '₽',
};

export const FirstScreenPriceComponent = ({
  reqStartPoint,
  reqEndPoint,
  startPoint,
  endPoint,
  getPlacesPrice,
  placePrice,
  currency,
  setStartPoint,
  setEndPoint,
  getRoadCost,
  roadCost
}) => {
  const handlerPoint = (props, type) => {
    if (type === 'start') {
      reqStartPoint(props)
    } else if (type === 'end') {
      reqEndPoint(props)
    }
  };

  React.useEffect(() => {
    if (isValidPoint(startPoint) && isValidPoint(endPoint)) {
      getRoadCost(startPoint, endPoint, true)
    }
  }, [startPoint, endPoint, currency]);

  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.title}>
          {t("main.map.workingArea")}
        </div>
        <div className={styles.text}>
          {t("prices.description")}
        </div>
        <div className={styles.titleBlock}>
          {t("prices.find")}
        </div>
        <div className={styles.form}>
          <div className={styles.firstInput}>
            <AddressField
              fieldnumber={300}
              label={t('main.searchBlock.pickUpLocation')} 
              value={startPoint.name}
              fillCoords={(props) => handlerPoint(props, 'start')}
              fillDescx={(e) => {
                const newPoint = {...startPoint};
                newPoint.name = e.target.value;
                setStartPoint(newPoint);
              }}
              setPointData={(item) => setStartPoint({
                id: item.id,
                name: item.description,
                latitude: item.latitude,
                longitude: item.longitude,
                tariffZoneId: item.tariffZoneId
              })}
            />
          </div>
          <div className={styles.secondInput}>
            <AddressField
              fieldnumber={301}
              label={t('main.searchBlock.dropOffLocation')} 
              value={endPoint.name}
              fillCoords={(props) => handlerPoint(props, 'end')}
              fillDescx={(e) => {
                const newPoint = {...endPoint};
                newPoint.name = e.target.value;
                setEndPoint(newPoint);
              }}
              setPointData={(item) => setEndPoint({
                id: item.id,
                name: item.description,
                latitude: item.latitude,
                longitude: item.longitude,
                tariffZoneId: item.tariffZoneId
              })}
            />
          </div>
        </div>
        <div className={styles.pricesBlock}>
          <div className={styles.cost}>
            {
              roadCost.data && roadCost.data.error && <span>{t("prices.error")}</span>
            }
            {Array.isArray(roadCost.data) && (
              <>
                {t("prices.yourPrice")}
                <b>{currencies[roadCost.data[0].currency]} {roadCost.data[0].basicCost}</b>
              </>
            )}
          </div>
          <button className={styles.button} onClick={() => history.push('/booking')}>
            {t("prices.book")}
          </button>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.map}>
          <MapComponent
            getPlacesPrice={getPlacesPrice}
            placePrice={placePrice}
            currency={currency}
          />
        </div>
        <div className={styles.priceList}>
          {
            placeLoacation.map((item, index) => (
              <div className={styles.placeBlock} key={index}>
                <div className={styles.point} />
                <div className={styles.placeTextBlock}>
                  <div className={styles.placeTitle}>
                    {item.title}
                  </div>
                  <div className={styles.placeText}>
                    {item.text}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  placePrice: payment.placePrice,
  currency: payment.currency,
  roadCost: payment.roadCost
});

const mapDispatchToProps = dispatch => bindActionCreators({
  reqStartPoint,
  reqEndPoint,
  getPlacesPrice,
  setStartPoint,
  setEndPoint,
  getRoadCost
}, dispatch);

export const FirstScreenPrice = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirstScreenPriceComponent);