import React from 'react'
import cn from 'classnames'
import styles from './bookingcontroll.module.css';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css'
import arrow from '../../assets/arrow.png'
import { Button, CheckBox, TextArea, TextField } from '../../formfields'
import {
  setChildSpace,
  setFirstName,
  setFlightNumber,
  setLugguageSpace,
  setLastName,
  setPhone,
  setEmail,
  setDescriptionTour
} from '../../modules/info/actions';

export const PersonalInfoComponent = ({step, setStep, setDescriptionTour, ...other}) => {
  const { t } = useTranslation();
  const [isFly, setFly] = React.useState(false);
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isNext = other.firstName && other.phone;
  return (
    <div>
      <h1 className={styles.title}>
        {t("personalInfo.personalInfoSectionTitle")}
        <img alt="/" src={arrow} />
        <span>{t("personalInfo.paymentSectionTitle")}</span>
      </h1>
      <div className={cn(styles.formDateBordered, styles.formWithSmallText)}>
        <div className={styles.fname}>
          <TextField
            label={t("personalInfo.firstName")}
            value={other.firstName}
            onChange={(e) => other.setFirstName(e.target.value)}
            required
          />
        </div>
        <div className={styles.dividerTime} />
        <div className={styles.fname}>
          <TextField
            label={t("personalInfo.lastName")}
            value={other.lastName}
            onChange={(e) => other.setLastName(e.target.value)}
          />
        </div>
      </div>
      <span className={styles.smallText}>{t("personalInfo.smallTextName")}</span>
      <div className={cn(styles.phoneContainer, styles.formWithSmallText)}>
        <PhoneInput
          specialLabel={<div>{t("personalInfo.phone")}<span> *</span></div>}
          country={'ru'}
          value={other.phone}
          onChange={(e) => other.setPhone(e)}
        />
      </div>
      <span className={styles.smallText}>{t("personalInfo.smallTextPhone")}</span>
      <div className={cn(styles.email, styles.inputForm, styles.formWithSmallText)}>
        <TextField
          label="E-mail"
          value={other.email}
          onChange={(e) => other.setEmail(e.target.value)}
        />
      </div>
      <span className={styles.smallText}>{t("personalInfo.smallTextEmail")}</span>
      <div className={styles.textareaWrapper}>
        <TextArea
          label={t('personalInfo.personalDetails')}
          value={other.descriptionTour}
          onChange={(e) => setDescriptionTour(e.target.value)}
        />
      </div>
      <div className={styles.formCheckbox}>
        <CheckBox
          text={t("carBlock.tracking")}
          checked={isFly}
          onChange={setFly}
        />
      </div>
      <span className={styles.text}> {t("personalInfo.fly")}</span>
      {
        isFly && (
          <div className={cn(styles.formSmall, styles.inputForm)}>
            <TextField
              label={t("personalDetails.flightNumber")}
              value={other.flightNumber}
              onChange={(e) => other.setFlightNumber(e.target.value)}
              required
            />
          </div>
        )
      }
      {/*
        <Button
          text={t("personalInfo.back")}
          onClick={() => setStep(0)}
        />
      */}
      <Button
        text={t("personalInfo.proceedToPayment")}
        onClick={() => setStep(2)}
        disabled={!isNext}
      />
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  firstName: userInfo.firstName,
  lastName: userInfo.lastName,
  phone: userInfo.phone,
  lagguageSpace: userInfo.lagguageSpace,
  childSpace: userInfo.childSpace,
  flightNumber: userInfo.flightNumber,
  email: userInfo.email,
  descriptionTour: userInfo.descriptionTour
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setChildSpace,
  setFirstName,
  setFlightNumber,
  setLugguageSpace,
  setLastName,
  setPhone,
  setEmail,
  setDescriptionTour
}, dispatch);

export const PersonalInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalInfoComponent);