import React from 'react'
import { useHistory } from "react-router-dom";
import styles from './maintwo.module.css'
import { useTranslation } from "react-i18next";
import cn from 'classnames'
import { Button } from '../../formfields'

import accessible from '../../assets/options/accessible-24px.svg'
import bag from '../../assets/options/work-24px.svg'
import person from '../../assets/options/person.svg'
import power from '../../assets/options/offline_bolt-24px.svg'
import wifi from '../../assets/options/wifi-24px.svg'
import car from '../../assets/main-two/two-image-car.png'
import gradient from '../../assets/main-two/gradient.png'

const listContent = [
  {
    text: 'main.two.infoPas',
    image: person
  },
  {
    text: 'main.two.infoLanguage',
    image: bag
  },
  {
    text: 'main.two.infoUsb',
    image: power
  },
  {
    text: 'main.two.infoWifi',
    image: wifi
  },
  {
    text: 'main.two.infoAvailable',
    image: accessible
  },
];

export const MainTwo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
      <span className={styles.title}>
        {t("main.two.title")}
      </span>
        <div className={styles.list}>
          {
            listContent.map((item, index) => (
              <div key={index} className={styles.listContent}>
                <img alt="/" src={item.image} />
                <span>{t(item.text)}</span>
              </div>
            ))
          }
        </div>
        <div className={cn(styles.leftFooter, styles.mobile)}>
          <div className={styles.buttonsBlock}>
            <div className={styles.buttonMargin}>
              <Button
                onClick={() => history.push('/cars')}
                text={t("main.two.bookATransfer")}
              />
            </div>
            {/*<Button
              noFill
              text={t("main.two.orIndividualTour")}
            />*/}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <img alt="/" className={styles.gradient} src={gradient} />
        <img alt="/" className={styles.car} src={car} />
        <span className={styles.name}>
          Mercedes-Benz Vito
        </span>
        <p className={styles.text}>
          {t("main.two.description")}
        </p>
      </div>
      <div className={cn(styles.leftFooter, styles.mobileView)}>
        <div className={styles.buttonsBlock}>
          <div className={styles.buttonMargin}>
            <Button
              onClick={() => history.push('/cars')}
              text={t("main.two.bookATransfer")}
            />
          </div>
          {/*<Button
            noFill
            text={t("main.two.orIndividualTour")}
          />*/}
        </div>
      </div>
    </div>
  );
};