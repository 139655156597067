import React from 'react'
import styles from './right.module.css';
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";

const date = [
  [
    {
      title: 'personalDetails.Client',
      name: 'fullName'
    },
    {
      title: 'personalDetails.lagguageSpace',
      name: 'lagguageSpace'
    },
  ],
  [
    {
      title: 'personalDetails.phone',
      name: 'phone'
    },
    {
      title: 'personalDetails.childSeat',
      name: 'childSpace'
    },
  ],
  [
    {
      title: 'E-mail',
      name: 'email'
    },
    {
      title: 'personalDetails.flightNumber',
      name: 'flightNumber'
    },
  ],
];

export const PersonalDetailsComponent = (props) => {
  const newProps = {
    ...props,
    fullName: `${props.lastName} ${props.firstName}`
  };
  const { t } = useTranslation();
  return (
    <div className={styles.personaldetails}>
      <div className={styles.title}>
        {t("personalDetails.title")}
      </div>
      <div className={styles.personBlock}>
        {
          date.map((item, index) => (
            <div className={styles.wrpaerPerson} key={index}>
              {
                item.map((person, personIndex) => (
                  <div className={styles.content} key={personIndex}>
                    <div className={styles.greyText}>
                      {t(person.title)}
                    </div>
                    <div className={styles.blackText}>
                      {newProps[person.name] || t("personalDetails.noNeed")}
                    </div>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  firstName: userInfo.firstName,
  lastName: userInfo.lastName,
  phone: userInfo.phone,
  lagguageSpace: userInfo.lagguageSpace,
  childSpace: userInfo.childSpace,
  flightNumber: userInfo.flightNumber,
  email: userInfo.email,
});

export const PersonalDetails = connect(
  mapStateToProps,
)(PersonalDetailsComponent);