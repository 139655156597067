export const poligons = [
  {
    name: 'Antalya',
    color: '#4230FF',
    opacity: 0.32,
    borderColor: '#4230FF',
    coords: [
      [36.867809, 30.850002],
      [36.886871, 30.840876],
      [36.931264, 30.803102],
      [36.972796, 30.749611], //start next
      [36.975463, 30.735753],
      [36.971103, 30.655212],
      [36.940219, 30.623055],
      [36.935445, 30.615801],
      [36.907762, 30.626363],
      [36.902252, 30.621769],
      [36.903241, 30.615055],
      [36.900556, 30.608694],
      [36.887539, 30.616751],
      [36.864043, 30.621894],
      [36.861956, 30.620409],
      [36.860376, 30.617662],
      [36.859758, 30.616289],
      [36.859896, 30.614229],
      [36.860720, 30.612083],
      [36.864497, 30.607277],
      [36.864771, 30.604444],
      [36.863123, 30.596806],
      [36.860102, 30.595089],
      [36.857149, 30.592771],
      [36.854745, 30.588823],
      [36.853395, 30.587963],
      [36.849068, 30.587963],
      [36.835055, 30.580924],
      [36.837254, 30.589422],
      [36.834643, 30.590452],
      [36.832720, 30.593799],
      [36.829353, 30.594571],
      [36.825575, 30.593345],
      [36.821332, 30.592462],
      [36.820322, 30.593728],
      [36.844838, 30.617203],
      [36.867594, 30.648819],
      [36.880174, 30.670552],
      [36.883990, 30.679387],
      [36.884979, 30.701827],
      [36.874379, 30.708894],
      [36.866322, 30.723736],
      [36.853598, 30.739992],
      [36.845963, 30.756424],
      [36.850488, 30.780808],
      [36.852043, 30.793530],
      [36.845822, 30.799184],
      [36.848367, 30.826041],
      [36.851714, 30.853910],
    ]
  },
  {
    name: 'Belek',
    color: '#FF6B00',
    opacity: 0.32,
    borderColor: '#FF6B00',
    coords: [
      [36.867809, 30.850002],
      [36.886871, 30.840876],
      [36.931264, 30.803102],
      [36.972796, 30.749611], //start next
      [36.979312, 30.844951],
      [36.977898, 30.877994],
      [36.950554, 31.011933],
      [36.955269, 31.079198],
      [36.954326, 31.122861],
      [36.952440, 31.187175],
      [36.944896, 31.219628],
      [36.916596, 31.235559],
      [36.869407, 31.306364],
      [36.856189, 31.345306], //end
      [36.826912, 31.342356],
      [36.811797, 31.342356],
      [36.806128, 31.336456], //end
      [36.817466, 31.266831],
      [36.827385, 31.200156],
      [36.842496, 31.075658],
      [36.857133, 30.985971],
      [36.853828, 30.922837],
      [36.851940, 30.855572],
    ]
  },
  {
    name: 'Side',
    color: '#CE4CC9',
    opacity: 0.32,
    borderColor: '#CE4CC9',
    coords: [
      [36.856189, 31.345306],
      [36.826912, 31.342356],
      [36.811381, 31.343788],
      [36.806047, 31.337981],
      [36.799042, 31.357697],
      [36.779849, 31.386883],
      [36.771377, 31.390602],
      [36.765976, 31.382698],
      [36.763089, 31.386999],
      [36.765697, 31.394555],
      [36.756917, 31.412325],
      [36.736401, 31.493443],
      [36.715880, 31.549675],
      [36.696696, 31.588678], //end
      [36.740153, 31.633090],
      [36.745679, 31.728161], //end
      [36.784746, 31.667572],
      [36.813145, 31.542946],
      [36.834044, 31.463145],
      [36.850601, 31.401571],
    ]
  },
  {
    name: 'Avsallar',
    color: '#B0CF36',
    opacity: 0.32,
    borderColor: '#B0CF36',
    coords: [
      [36.696696, 31.588678],
      [36.740153, 31.633090],
      [36.745679, 31.728161],
      [36.717164, 31.764280],
      [36.689666, 31.824783],
      [36.664322, 31.875266], //start
      [36.626291, 31.875266],
      [36.604948, 31.865632],
      [36.585147, 31.865246],//start
      [36.595667, 31.826709],
      [36.593810, 31.813992],
      [36.606495, 31.786246],
      [36.605567, 31.772758],
      [36.613610, 31.772758],
      [36.623198, 31.755417],
      [36.637733, 31.724587],
      [36.643918, 31.672563],
      [36.676068, 31.621309],
    ]
  },
  {
    name: 'Alanya',
    color: '#53AFCC',
    opacity: 0.32,
    borderColor: '#53AFCC',
    coords: [
      [36.664322, 31.875266], //start
      [36.626291, 31.875266],
      [36.604948, 31.865632],
      [36.585147, 31.865246],//start
      [36.574751, 31.891370],
      [36.559122, 31.938854],
      [36.543334, 31.981630],
      [36.529710, 31.987025],
      [36.529710, 32.001669],
      [36.542405, 32.007835],
      [36.528471, 32.042903],
      [36.495640, 32.084523],
      [36.459695, 32.121518],
      [36.560051, 32.152347],
      [36.599662, 32.119591],
      [36.631212, 32.073347],
      [36.659658, 32.018625],
      [36.668623, 31.943478],
    ]
  }
];