import React from 'react'
import styles from './booking.module.css';
import {
  MainFooter,
  HeaderBooking,
  BookingController,
} from '../../components/index'
import moment from 'moment';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { clearFields, setGoogleAPILoaded } from '../../modules/info/actions'
import { getCars,
         getGoogleMapsKey } from '../../modules/requests/actions'

export const BookingPageComponent = (props) => {
  const [step, setStep] = React.useState(props.success ? 4 : (props.fail ? 5 : 1));

  const datesError = moment(props.endDate).isBefore(props.beginDate);
  const isNext = props.startPoint && props.endPoint && props.startDate && !datesError && props.passengers;
  React.useEffect(() => {
    if (!isNext && props.history.location.pathname === '/booking') {
     props.history.push('/')
    }
  }, [isNext]);

  const [bookingPageState, setBookingPageState] = React.useState(1);
  const [googleState, setGoogleState] = React.useState('');

  // Cleanup previous data and Connect to Google Maps
  React.useEffect(() => {
    //console.log('-> bookingPageState='+bookingPageState+'; googleMapsKey='+props.googleMapsKey+'; googleState='+googleState);
    if (bookingPageState === 0) {

      props.clearFields();
      setBookingPageState(1);
    } else {
      if (googleState === '') {

        if (props.googleMapsKey === null || props.googleMapsKey === undefined) {
          props.getGoogleMapsKey();
        } else if (props.googleMapsKey.length) {
          const script = document.createElement("script");

          script.type="text/javascript";
          script.src="https://maps.googleapis.com/maps/api/js?key="+props.googleMapsKey+"&libraries=places,establishment,geometry,drawing";
          script.addEventListener("load", () => { console.log('script loaded'); setGoogleState('complete')});
          script.addEventListener("error", () => { console.log('script load error'); setGoogleState('error')});

          document.body.appendChild(script);
          setGoogleState('loading');
        }
      } else if (googleState === 'complete') {
        props.setGoogleAPILoaded(true);
      }
    }
  }, [bookingPageState, props.googleMapsKey, googleState]);

  return (
    <div className={styles.content}>
      <div className={styles.booking}>
        <HeaderBooking {...props} />
        <BookingController
          {...props}
          step={step}
          setStep={setStep}
        />
      </div>
      <MainFooter />
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  googleMapsKey: userInfo.googleMapsKey,

  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  startDate: userInfo.startDate,
  endDate: userInfo.endDate,
  passengers: userInfo.passengers,
  transferClass: userInfo.transferClass,
  currency: payment.currency,
  cars: payment.cars.data,
  childSpace: userInfo.childSpace,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFields,
  getGoogleMapsKey,
  setGoogleAPILoaded,
  getCars,
}, dispatch);

export const BookingPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingPageComponent);
