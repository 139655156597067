import moment from 'moment'

import { infoActionTypes } from '../info/constants'

import {
  SET_LANGUAGE,
  SET_CURRENCY,
  GET_PLACES_PRICE_SUCCESS,
  GET_POPULAR_PLACE,
  GET_ROAD_COST,
  GET_ROAD_COST_ERROR,
  GET_CARD_INFO_ERROR,
  GET_CARD_INFO,
  GET_CARS_ERROR,
  GET_CARTYPES_ERROR,
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_ERROR
} from './constants'
import { requestsActionTypes } from '../requests/constants'

const URL = process.env[process.env.REACT_APP_URL];


/*-----------------------------------------------------*/
export const getGoogleMapsKey = () => {
  return (dispatch) => {
    fetch(`${URL}/system/getSettings`, {
      method: 'POST',
      body: JSON.stringify({
        "entity": ['useGoogleMapsFlag', 'googleKey']
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
     .then(response => response.json())
     .then(result => {
       if (!result.error) {
         dispatch(successGetGoogleMapsKey(result))
       }
       //else
       //  dispatch(errorGetSystemSettings(result.error, tabIndex))
     })
     //.catch((error) => dispatch(errorGetSystemSettings(error, tabIndex)));
  }
};


const successGetGoogleMapsKey = (result) => {
  let payload = {
    result: 'Ok',
    settings: result.settings
  };

  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_GOOGLEMAPS_KEY,
      payload,
    });
  }
};


/*------ getPointList ------*/
export const getPointList = (description) => {
  return (dispatch) => {
    fetch(`${URL}/point/find`, {
      method: 'POST',
      body: JSON.stringify({
          "description": description
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
     .then(response => response.json())
     .then(result => {
       dispatch(successGetPointSelect(result))
     })
     .catch((error) => dispatch(errorGetPointSelect(error)));
  }
};

const successGetPointSelect = (result) => {
  let payload = {
    result: 'Ok',
    pointCount: result.pointCount,
    pointList: result.pointList
  };

  return dispatch => {
    dispatch({
      type: requestsActionTypes.SELECT_POINT_LIST,
      payload,
    });
  }
};

const errorGetPointSelect = (error) => {
  let payload = {
    result: 'Error',
    error: error,
  };

  alert(error);

  return dispatch => {
    dispatch({
      type: requestsActionTypes.LOOKUP_REQUEST_ERROR,
      payload,
    });
  }
};

/*------ addPoint ------*/
export const addPoint = (pointData) => {
  return (dispatch) => {

    fetch(`${URL}/point/add`, {
      method: 'POST',
      body: JSON.stringify({
          "latitude": pointData.latitude,
          "longitude": pointData.longitude,
          "description": pointData.description
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
     .then(response => response.json())
     .then(result => {
       dispatch(successAddPoint(result))
     })
     .catch((error) => dispatch(errorAddPoint(error)));
  }
};

const successAddPoint = (result) => {
  let payload = {
    result: 'Ok',
    pointId: result.id,
    tariffZoneId: result.tariffZoneId
  };

  return dispatch => {
    dispatch({
      type: requestsActionTypes.LOOKUP_POINT_DATA,
      payload,
    });
  }
};

const errorAddPoint = (error) => {
  let payload = {
    result: 'Error',
    error: error,
  };

  alert(error);

  return dispatch => {
    dispatch({
      type: requestsActionTypes.LOOKUP_REQUEST_ERROR,
      payload,
    });
  }
};

/*------ createTransfer ------*/
export const createTransfer = (isIndividual) => {
  return (dispatch, getState) => {
    const {
      userInfo: {
        startPoint,
        endPoint,
        startDate,
        endDate,

        passengers,
        childSpace,
        lagguageSpace,

        orderAmount,
        orderCurrency,

        flightNumber,
        firstName,
        lastName,
        phone,
        email,
        needReceipt,
        receiptCompany,
        receiptDetails,
        descriptionTour
      },
      payment: {
        roadCost
      }
    } = getState();

    const startTariffZone = startPoint.tariffZoneId || (roadCost.data && roadCost.data[0] && roadCost.data[0].route && roadCost.data[0].route.fromZone && roadCost.data[0].route.fromZone.id);
    const endTariffZone = endPoint.tariffZoneId || (roadCost.data && roadCost.data[0] && roadCost.data[0].route && roadCost.data[0].route.toZone && roadCost.data[0].route.toZone.id);

    const transferVariant = roadCost.data && roadCost.data[0] && roadCost.data[0].transferVariants ? roadCost.data[0].transferVariants[0] : {};

    fetch(`${URL}/order/add`, {
      method: 'POST',
      body: JSON.stringify({
          "client" : {
            "name": firstName + ' ' + lastName,
            "phone": phone,
            "mail": email,
            "notes": descriptionTour || ''
          },
          "route": {
             "tourKind": {
               "code": (!!isIndividual) ? 2 : 1
             },
             "twoWayFlag": endDate ? true : false,
             "directDate": startDate,
             "returnDate": endDate,
             "startPoint": {
               "id": +startPoint.id,
               "latitude": +startPoint.latitude,
               "longitude": +startPoint.longitude,
               "tariffZone": startTariffZone
             },
             "endPoint": {
               "id": +endPoint.id,
               "latitude": +endPoint.latitude,
               "longitude": +endPoint.longitude,
               "tariffZone": endTariffZone
             },
             "flightNumber": flightNumber
          },
          "passengers": {
             "totalCount": +passengers + (+childSpace || 0),
             "adultCount": +passengers,
             "childCount": +childSpace || 0,
             "luggageCount": +lagguageSpace || 0
          },
          "processing": {
             "carTypeList": transferVariant.carTypes ? transferVariant.carTypes.map(item => +item.id) : []
          },
          "cost": {
             "amount": orderAmount,
             "currency": orderCurrency
          },
          "receipt": needReceipt ? {
             "firstName": firstName,
             "lastName": lastName,
             "company": receiptCompany,
             "details": receiptDetails
          } : undefined,
          "channel": 'customer'
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        dispatch(successCreateTransfer(result));
      })
      .catch((error) => dispatch(errorCreateTransfer(error)));
  }
};

export const successCreateTransfer = (payload) => {
  return dispatch => {
    dispatch({
      type: CREATE_TRANSFER_SUCCESS,
      payload,
    });
    dispatch({
      type: infoActionTypes.SET_ORDER_ID,
      payload: payload.id,
    });
  }
};

export const errorCreateTransfer = (payload) => {
  return dispatch => {
    dispatch({
      type: CREATE_TRANSFER_ERROR,
      payload,
    });
  }
};


/*------ createOrderFile ------*/
export const createOrderFile = () => {
  return (dispatch, getState) => {
    const {
      orderId
    } = getState().userInfo;

    const lang = getState().payment.language.value === "ru" ? "rus" : "eng";

    fetch(`${URL}/order/createFile`, {
      method: 'POST',
      body: JSON.stringify({
          "id": orderId,
          "language": lang
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: infoActionTypes.SET_DOCUMENT_NAME,
          payload: response
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }
};


/*------ sendOrderLetterToAdmin ------*/
export const sendOrderLetterToAdmin = () => {
  return (dispatch, getState) => {
    const {
      orderId
    } = getState().userInfo;

    const lang = getState().payment.language.value === "ru" ? "rus" : "eng";

    fetch(`${URL}/order/sendLetterToAdmin`, {
      method: 'POST',
      body: JSON.stringify({
          "id": orderId,
          "language": lang
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .catch((error) => {
        console.log(error)
      });
  }
};


export const getPlacesPrice = (location) => {
  return (dispatch, getState) => {
    const currency = getState().payment.currency.value;
    fetch(`${URL}/tariff/byRegions?currency=${currency}&from=${location}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        dispatch(successPlacePrice(result))
      })
      .catch((error) => console.log('Place', error));
  }
};

export const successPlacePrice = (payload) => {
  return dispatch => {
    dispatch({
      type: GET_PLACES_PRICE_SUCCESS,
      payload,
    });
  }
};

export const setCurrency = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_CURRENCY,
      payload,
    });
  }
};

export const setLanguage = (payload) => {
  return dispatch => {
    dispatch({
      type: SET_LANGUAGE,
      payload,
    });
  }
};

export const getPopularPlacesPrice = () => {
  return (dispatch, getState) => {
    const currency = getState().payment.currency.value;
    fetch(`${URL}/tariff/byRegions?currency=${currency}&favorites=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: GET_POPULAR_PLACE,
          payload: result
        });
      })
      .catch((error) => console.log('Popular', error));
  }
};

export const getRoadCost = (startPoint, endPoint, checkOnlyFlag) => {
  return (dispatch, getState) => {
    const {
      userInfo: {
        startDate,
        endDate,
        passengers,
        childSpace
      },
      payment: {
        currency: {
          value: currency
        }
      }
    } = getState();

    let searchConditions = '';

    searchConditions = 'currency='+currency;
    searchConditions = searchConditions+('&from='+startPoint.latitude+','+startPoint.longitude);
    searchConditions = searchConditions+('&to='+endPoint.latitude+','+endPoint.longitude);

    if (checkOnlyFlag) {
      searchConditions = searchConditions+('&checkOnly=true');
    } else {
      if (passengers)
        searchConditions = searchConditions+('&passengers='+passengers);
      if (childSpace)
        searchConditions = searchConditions+('&child='+childSpace);
    
      if (endDate)
        searchConditions = searchConditions+('&return=true');
    
      searchConditions = searchConditions+('&carClass=1');
    
      searchConditions = searchConditions+('&checkAvailableCars=true');
    }

    fetch(`${URL}/tariff/byRegions?${searchConditions}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        dispatch({
          type: GET_ROAD_COST,
          payload: result
        });
      })
      .catch(({ error }) => {
        dispatch({
          type: GET_ROAD_COST_ERROR,
          errorCost: error
        });
    });
  }
};

/*------ getAuthAmount ------*/
export const getAuthAmount = () => {
  return (dispatch, getState) => {
    const {
      orderAmount,
      orderCurrency
    } = getState().userInfo;

    fetch(`${URL}/payment/getAuthAmount`, {
      method: 'POST',
      body: JSON.stringify({
          "cost": {
             "amount": orderAmount,
             "currency": orderCurrency
          }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        dispatch(successGetAuthAmount(result));
      })
      //.catch((error) => dispatch(errorGetAuthAmount(error)));
  }
};

export const successGetAuthAmount = (payload) => {
  return dispatch => {
    dispatch({
      type: infoActionTypes.SET_AUTH_AMT,
      payload: +payload.auth.amount,
    });
    dispatch({
      type: infoActionTypes.SET_AUTH_CURR,
      payload: payload.auth.currency,
    });
  }
};


/*------ processPayment ------*/
export const processPayment = () => {

  return (dispatch, getState) => {
    const {
      orderId,
      cardPAN,
      cardExpDate,
      cardCVV2,
      authAmount,
      authCurrency
    } = getState().userInfo;
    const lang = getState().payment.language.value === "ru" ? "rus" : "eng";

    const body = {
        "orderId": orderId,
        "onlinePayment": true,
        "pan": cardPAN,
        "expYear": cardExpDate.split('/')[1],
        "expMonth": cardExpDate.split('/')[0],
        "cv2": cardCVV2,
        "authAmount": authAmount,
        "authCurrency": authCurrency,
        "language": lang,
        "okURL": `${URL}/payment/success`,
        "failURL": `${URL}/payment/fail`
      };
    fetch(`${URL}/payment/process`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: GET_CARD_INFO,
          payload: response
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CARD_INFO_ERROR,
          payload: error
        });
      });
  }
};

export const getCarTypes = () => {
  return (dispatch) => {
    fetch(`${URL}/car/findType`, {
      method: 'POST',
      body: JSON.stringify({
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: infoActionTypes.SET_CARTYPE_LIST,
          payload: response
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CARTYPES_ERROR,
          payload: error
        });
      });
  }
};


export const getCars = (passengerCapacity, childrenCapacity, luggageCapacity) => {
  return (dispatch) => {
    fetch(`${URL}/car/find`, {
      method: 'POST',
      body: JSON.stringify({
        "totalMinCapacity": passengerCapacity + childrenCapacity,
        "childMinCapacity": childrenCapacity
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: infoActionTypes.SET_CAR_LIST,
          payload: response
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CARS_ERROR,
          payload: error
        });
      });
  }
};

export const getOrderInfo = (orderId, orderTimestamp) => {
  return (dispatch, getState) => {
    fetch(`${URL}/order/info/`, {
      method: 'POST',
      body: JSON.stringify({
        "id": orderId,
        "timestamp": orderTimestamp
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: infoActionTypes.SET_ORDER_INFO,
          payload: response
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }
};

/*-------------------------------*/
export const clearSelectData = () => {
  return dispatch => {
    dispatch({
      type: requestsActionTypes.CLEAR_SELECT_DATA
    });
  }
};

/*-------------------------------*/
export const clearLookupData = () => {
  return dispatch => {
    dispatch({
      type: requestsActionTypes.CLEAR_LOOKUP_DATA
    });
  }
};
