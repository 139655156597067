export const GET_PLACES_PRICE = 'get/placesPrice';
export const GET_PLACES_PRICE_SUCCESS = 'get/placesPriceSuccess';
export const GET_ROAD_COST = 'get/roadCost';
export const GET_POPULAR_PLACE = 'get/popularPlace';
export const GET_ROAD_COST_ERROR = 'get/roadCostError';

export const SET_CURRENCY = 'set/currency';
export const SET_LANGUAGE = 'set/language';

export const GET_CARD_INFO = 'get/GET_CARD_INFO';
export const GET_CARD_INFO_ERROR = 'get/GET_CARD_INFO_ERROR';

export const GET_CARTYPES_ERROR = 'get/GET_CARTYPES_ERROR';
export const GET_CARS_ERROR = 'get/GET_CARS_ERROR';

export const CREATE_TRANSFER_SUCCESS = 'createTransfer/success';
export const CREATE_TRANSFER_ERROR = 'createTransfer/error';


export const requestsActionTypes = 
{
  SELECT_POINT_LIST:     'select/pointList',
  LOOKUP_POINT_DATA:     'lookup/pointData',

  CLEAR_SELECT_DATA:     'clear/select',

  LOOKUP_REQUEST_ERROR:  'lookup/requestError',

  CLEAR_LOOKUP_DATA:     'clear/lookup',

  CLEAR_ROAD_COST:       'clear/roadCost',
  CLEAR_PAYS:            'clear/pays'
}
