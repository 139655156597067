import React from 'react'
import styles from './right.module.css';
import cn from 'classnames'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";

const date = [
  {
    title: 'payInfo.maskedPan',
    titleTwo: 'payInfo.maskedOffline',
    name: 'maskedPan'
  },
  {
    title: 'payInfo.receipt',
    name: 'fullName'
  },
  {
    title: 'payInfo.details',
    name: `descriptionTour`
  },
];

export const PayDetailsFc = (props) => {
  const { t } = useTranslation();
  const newProps = {
    ...props,
    fullName: `${props.lastName} ${props.firstName}`
  };
  return (
    <div className={styles.personaldetails}>
      <div className={styles.title}>
        {t("personalInfo.paymentSectionTitle")}
      </div>
      <div className={styles.personBlock}>
        {
          date.map((item, index) => (
            <div className={cn(styles.content)} key={index}>
              <div className={styles.greyText}>
                {item.titleTwo && !newProps[item.name] ? t(item.titleTwo) : t(item.title)}
              </div>
              <div className={styles.blackText}>
                {newProps[item.name] || '-'}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  firstName: userInfo.firstName,
  lastName: userInfo.lastName,
  maskedPan: userInfo.maskedPan,
  descriptionTour: userInfo.descriptionTour
});

export const PayDetails = connect(
  mapStateToProps,
)(PayDetailsFc);