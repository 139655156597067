import React from 'react'
import cn from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { isValidPoint } from '../utils'
import { Button } from '../../formfields'
import { Modal } from '../Modal'
import {
  setTransferClass,
} from '../../modules/info/actions'
import {
  getRoadCost,
  getCars
} from '../../modules/requests/actions'

import styles from './carblock.module.css';
import flight_land from '../../assets/Icons/flight_land-24px.svg'
import insert_invitation from '../../assets/Icons/insert_invitation-24px.svg'
import security from '../../assets/Icons/security-24px.svg'
import person from '../../assets/options/person.svg'
import work from '../../assets/options/work-24px.svg'
import bolt from '../../assets/options/offline_bolt-24px.svg'
import wifi from '../../assets/options/wifi-24px.svg'
import accessible from '../../assets/options/accessible-24px.svg'
import baby from '../../assets/options/baby.svg'
import media from '../../assets/options/media.svg'
import bar from '../../assets/options/bar.svg'
import help from '../../assets/options/mdi_help_outline.svg'
import help_active from '../../assets/options/mdi_help_outline_active.svg'

const icons = [
  {
    name: 'carBlock.tracking',
    image: flight_land
  },
  {
    name: 'carBlock.cancellation',
    image: insert_invitation
  },
  {
    name: 'carBlock.drive',
    image: security
  },
];

const currencies = {
  USD: '$',
  EUR: '€',
  TRY: '₺',
  RUB: '₽',
};


export const CarBlockComponent = ({
  setStep,
  step,
  startPoint,
  endPoint,
  startDate,
  endDate,
  passengers,
  transferClass,
  currency,
  getRoadCost,
  roadCost,
  childSpace,
}) => {
  React.useEffect(() => {
    if (isValidPoint(startPoint) && isValidPoint(endPoint)) {
      getRoadCost(startPoint, endPoint)
    }
  }, [startPoint, endPoint, currency, startDate, endDate, passengers, childSpace]);
  const [isOpen, setModal] = React.useState(null);

  const isIndividual = roadCost.data && Array.isArray(roadCost.data);

  const datesError = moment().isAfter(startDate) || moment(endDate).isBefore(startDate);

  const isNext = startPoint && endPoint && startDate && !datesError && passengers && isIndividual;
  const avilibleCars = roadCost.data && !roadCost.error && roadCost.data.length && roadCost.data[0].avilibleCars;
  const { t } = useTranslation();
  const openModal = (e, id) => {
    e.stopPropagation();
    setModal(id)
  };
  const aboutCar = avilibleCars && isOpen && avilibleCars.find((item) => item.id === isOpen);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.blocks}>
          {
            avilibleCars && avilibleCars.map((item, index) => (
              <div
                className={cn(styles.block, {[styles.activeBlock]: item.id === transferClass})}
                key={item.id}
              >
                <div className={styles.carTop}>
                  <div>
                    <span className={styles.text}>
                      {item.className}
                    </span>
                    <span className={styles.price}>
                      {currencies[currency.value]} {item.cost}
                    </span>
                  </div>
                  <div className={styles.car}>
                    <img alt="/" src={item.image} />
                  </div>
                </div>
                <div className={styles.carOptions}>
                  <div className={styles.option}>
                    <img alt={'/'} src={person} />
                    <span>{item.passengerCapacity}</span>
                  </div>
                  <div className={styles.option}>
                    <img alt={'/'} src={work} />
                    <span>{item.luggageCapacity}</span>
                  </div>
                  {
                    item.options && (
                      <>
                        <div className={cn(styles.option, { [styles.optionDisable]: !item.options.usb })}>
                          <img alt={'/'} src={bolt} />
                        </div>
                        <div className={cn(styles.option, { [styles.optionDisable]: !item.options.baby })}>
                          <img alt={'/'} src={baby} />
                        </div>
                        <div className={cn(styles.option, { [styles.optionDisable]: !item.options.media })}>
                          <img alt={'/'} src={media} />
                        </div>
                        <div className={cn(styles.option, { [styles.optionDisable]: !item.options.invalid })}>
                          <img alt={'/'} src={accessible} />
                        </div>
                        <div className={cn(styles.option, { [styles.optionDisable]: !item.options.wifi })}>
                          <img alt={'/'} src={wifi} />
                        </div>
                        <div className={cn(styles.option, { [styles.optionDisable]: !item.options.bar })}>
                          <img alt={'/'} src={bar} />
                        </div>
                      </>
                    )
                  }
                </div>
                <div
                  className={cn(styles.about, {[styles.aboutActive]: item.id === transferClass})}
                  onClick={(e) => openModal(e, item.id)}
                >
                  <img alt={'/'} src={item.id === transferClass ? help_active : help} />
                  <span className={styles.textAbout}>
                    About this car
                  </span>
                </div>
              </div>
            ))
          }
        </div>
        {
          (
            <div className={styles.bottom}>
              <Button
                text={t("carBlock.booking")}
                onClick={() => setStep(1)}
                disabled={!isNext}
              />
              <div className={styles.icons}>
                {
                  icons.map((item, index) => (
                    <div key={index} className={styles.iconBlock}>
                      <img alt="/" src={item.image} />
                      <span>{t(item.name)}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
        {
          avilibleCars && !avilibleCars.length && (
            <div className={styles.errorCar}>
              Машин с таким колличеством пассажиров не найдено
            </div>
          )
        }
      </div>
      {
        aboutCar && (
          <Modal onClose={() => setModal(null)}>
            <div className={styles.blockModal}>
              <div className={styles.iconContainer}>
                <img className={styles.icon} alt="/" src={aboutCar.image} />
              </div>
              <span className={styles.blockText}>{aboutCar.className}</span>
              <span className={styles.nameCar}>{aboutCar.name}</span>
              <div className={styles.optionsModal}>
                <div className={styles.optionModal}>
                  <img alt={'/'} src={person} />
                  <span>Up to {aboutCar.passengerCapacity} passengers</span>
                </div>
                <div className={styles.optionModal}>
                  <img alt={'/'} src={work} />
                  <span>Up to {aboutCar.luggageCapacity} medium suitcases</span>
                </div>
                {
                  aboutCar.options && (
                    <>
                    {
                      aboutCar.options.usb && (
                        <div className={styles.optionModal}>
                          <img alt={'/'} src={bolt} />
                          <span>USB power on board</span>
                        </div>
                      )
                    }
                    {
                      aboutCar.options.baby && (
                        <div className={styles.optionModal}>
                          <img alt={'/'} src={baby} />
                          <span>Baby seats on demand</span>
                        </div>
                      )
                    }
                    {
                      aboutCar.options.media && (
                        <div className={styles.optionModal}>
                          <img alt={'/'} src={media} />
                          <span>Multimedia system</span>
                        </div>
                      )
                    }
                    {
                      aboutCar.options.invalid && (
                        <div className={styles.optionModal}>
                          <img alt={'/'} src={accessible} />
                          <span>Available for persons with disabilities</span>
                        </div>
                      )
                    }
                    {
                      aboutCar.options.wifi && (
                        <div className={styles.optionModal}>
                          <img alt={'/'} src={wifi} />
                          <span>Wi-Fi for all ride long</span>
                        </div>
                      )
                    }
                    {
                      aboutCar.options.bar && (
                        <div className={styles.optionModal}>
                          <img alt={'/'} src={bar} />
                          <span>Mini bar on board</span>
                        </div>
                      )
                    }
                    </>
                  )
                }
              </div>
            </div>
          </Modal>
        )
      }
    </div>
  )
};

const mapStateToProps = ({ userInfo, payment }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  startDate: userInfo.startDate,
  endDate: userInfo.endDate,
  passengers: userInfo.passengers,
  transferClass: userInfo.transferClass,
  currency: payment.currency,
  roadCost: payment.roadCost,
  cars: payment.cars.data,
  childSpace: userInfo.childSpace,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setTransferClass,
  getRoadCost,
  getCars
}, dispatch);

export const CarBlock = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarBlockComponent);