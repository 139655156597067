/*global google*/
import React from 'react';
import { useSelector, connect } from 'react-redux'
import { compose, withProps, lifecycle } from "recompose"
import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker } from "react-google-maps"
import { isValidPoint } from '../utils'
import mark from '../../assets/mappoint.svg'

/* mapStateToProps should be declared BEFORE Component -
   otherwise mapped props won't be seen in the latter */
const mapStateToProps = ({ userInfo }) => ({
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
});

const MyMapComponent = compose(
  connect(
    mapStateToProps,
  ),
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      if (this.props.startPoint.name && this.props.endPoint.name) {
        const DirectionsService = new google.maps.DirectionsService();
        DirectionsService.route({
          origin: new google.maps.LatLng(this.props.startPoint.latitude, this.props.startPoint.longitude),
          destination: new google.maps.LatLng(this.props.endPoint.latitude, this.props.endPoint.longitude),
          travelMode: google.maps.TravelMode.DRIVING,
        }, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result,
            });
          } else {
            /*console.error(`error fetching directions ${result}`, result);*/
          }
        });
      }
    },
    componentDidUpdate(nextProps) {
      if ((nextProps.startPoint.name !== this.props.startPoint.name) || (nextProps.endPoint.name !== this.props.endPoint.name)) {
        if (this.props.startPoint.name && this.props.endPoint.name) {
          const DirectionsService = new google.maps.DirectionsService();
          DirectionsService.route({
            origin: new google.maps.LatLng(this.props.startPoint.latitude, this.props.startPoint.longitude),
            destination: new google.maps.LatLng(this.props.endPoint.latitude, this.props.endPoint.longitude),
            travelMode: google.maps.TravelMode.DRIVING,
          }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result,
              });
            } else {
              //console.error(`error fetching directions ${result}`, result);
            }
          });
        }
      }
    }
  }),
)((props) =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={new google.maps.LatLng(36.90812, 30.69556)}
  >
    {props.directions && (
      <DirectionsRenderer
        directions={props.directions}
        options={{
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: '#4230FF',
            strokeOpacity: 1,
            strokeWeight: 5
          }
        }}
      />
    )}
    {
      isValidPoint(props.startPoint) && (
        <Marker
          icon={mark}
          position={{ lat: props.startPoint.latitude, lng: props.startPoint.longitude }}
        />
      )
    }
    {
      isValidPoint(props.endPoint) && (
        <Marker
          icon={mark}
          position={{ lat: props.endPoint.latitude, lng: props.endPoint.longitude }}
        />
      )
    }
  </GoogleMap>
);

export const MapComponent = (props) => {
  const googleAPILoaded = useSelector(({userInfo}) => userInfo.googleAPILoaded);

  //console.log('MapComponent: googleAPILoaded='+JSON.stringify(googleAPILoaded));

  if (googleAPILoaded) {
    return (<MyMapComponent isMarkerShown />);
  } else {
    return (<div></div>);
  }
};


