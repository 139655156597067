import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import { MainPage } from './Pages/MainPage/MainPage';
import { BookingPage } from './Pages/BookingPage';
import { Cars } from './Pages/Cars/Cars'
import { Prices } from './Pages/Prices/Prices'
import { AboutPage } from './Pages/AboutPage'
import { ContactsPage } from './Pages/ContactsPage'
import { Terms } from './Pages/Terms'
import { Privacy } from './Pages/Privacy'

function App() {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <MainPage {...props} />} />
      <Route exact path="/booking" render={(props) => <BookingPage {...props} />} />
      <Route exact path="/booking/fail" render={(props) => <BookingPage {...props} fail />} />
      <Route exact path="/booking/fail/:id" render={(props) => <BookingPage {...props} fail />} />
      <Route exact path="/booking/success" render={(props) => <BookingPage {...props} success />} />
      <Route exact path="/booking/success/:id" render={(props) => <BookingPage {...props} success />} />
      <Route exact path="/cars" render={(props) => <Cars {...props} />} />
      <Route exact path="/prices" render={(props) => <Prices {...props} />} />
      <Route exact path="/about" render={(props) => <AboutPage {...props} />} />
      <Route exact path="/contacts" render={(props) => <ContactsPage {...props} />} />
      <Route exact path="/terms" render={(props) => <Terms {...props} />} />
      <Route exact path="/privacy" render={(props) => <Privacy {...props} />} />
    </Switch>
  );
}

export default App;
