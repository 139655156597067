import React from 'react'
import styles from './bookingcontroll.module.css';
import done from '../../assets/done.png'
import { useTranslation } from "react-i18next";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';
import './animation.css'
import { Button } from '../../formfields'
import { sendOrderLetterToAdmin, createOrderFile } from '../../modules/requests/actions'
import { setDocumentName } from '../../modules/info/actions'

export const DoneComponent = ({
  orderId,
  startPoint,
  endPoint,
  startDate,
  email,
  transactionId,
  receiptRequired,
  documentName,
  sendOrderLetterToAdmin,
  createOrderFile,
  setDocumentName,
  pays
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (orderId) {
      sendOrderLetterToAdmin();
    }
  }, [orderId]);

  const documentURL = process.env[process.env.REACT_APP_URL];
  const[createOrderFileResult, setCreateOrderFileResult] = React.useState();
  /* ������ ���������, ����������� ���������� ������������������ �������:
     - createOrderFile
     - open created file in a new tab */
  React.useEffect(() => {
    if (createOrderFileResult === '')
    {
      if (documentName !== '')
      {
         setDocumentName('');
      }
      else
      {
         setCreateOrderFileResult('CreateFile');
      }
    }
    else if (createOrderFileResult === 'CreateFile')
    {
      if (documentName === '')
      {
         createOrderFile();
      }
      else
      {
         window.open(`${documentURL}/public/pdf/orders/${documentName}.pdf`, documentName);
         setCreateOrderFileResult();
      }
    };
  }, [createOrderFileResult, documentName]);

  const handleCreateOrderFile = () => {

    /* ��������� ������������������ ���������, � ������� ������� ������� ������������
       ����� (����� createOrderFile), � ����� - �������� ��������������� ����� � �����
       ������� (����� window.open) */
    setCreateOrderFileResult('');
  };

  return (
    <div>
      <h1 className={styles.title}>
        {t("done.title")}
      </h1>
      <div className={styles.progress}>
        <img src={done} />
      </div>
      <span className={styles.textBold}>
        {t("done.congrats")} <br />
        {t("done.succesfully")}
      </span>
      <div className={styles.divider} />
      <div className={styles.titleGray}>
        {t("done.details")}
      </div>
      <div className={styles.doneContainer}>
        {
          startDate && (
            <div className={styles.doneBlock}>
              <div className={styles.greyText}>
                {t("done.date")}
              </div>
              <div className={styles.blackText}>
                {moment(startDate).format('DD.MM.YYYY')} / {moment(startDate).format('HH:mm')} GMT{moment(startDate).format('Z')}
              </div>
            </div>
          )
        }
        {
          orderId && (
            <div className={styles.doneBlock}>
              <div className={styles.greyText}>
                {t("done.order")}
              </div>
              <div className={styles.blackText}>
                {orderId}
              </div>
            </div>
          )
        }
        {
          transactionId && (
            <div className={styles.doneBlock}>
              <div className={styles.greyText}>
                {t("done.transaction")}
              </div>
              <div className={styles.blackText}>
                {transactionId}
              </div>
            </div>
          )
        }
        {
          email && receiptRequired && (
            <span className={styles.textLight}>
              {t("done.textOne")}
              <span> {email}. </span>
              {t("done.textFour")}
            </span>
          )
        }
      </div>
      {
        orderId && (
          <Button
            text={t("done.print")}
            onClick={() => handleCreateOrderFile(orderId)}
          />
       )
      }
    </div>
  )
};

const mapStateToProps = ({ payment, userInfo }) => ({
  orderId: userInfo.orderId,
  startPoint: userInfo.startPoint,
  endPoint: userInfo.endPoint,
  startDate: userInfo.startDate,
  email: userInfo.email,
  transactionId: userInfo.transactionId,
  receiptRequired: userInfo.receiptRequired,
  documentName: userInfo.documentName,
  pays: payment.pays
});

const mapDispatchToProps = dispatch => bindActionCreators({
  sendOrderLetterToAdmin,
  createOrderFile,
  setDocumentName
}, dispatch);

export const Done = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoneComponent);