import React from 'react'
import styles from './checkbox.module.css';

export const CheckBox = ({ text, checked, onChange }) => {
  return (
    <label className={styles.container}>

      <input
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <span className={styles.checkmark} />
      {text}
    </label>
  )
};