/*global google*/
import React from 'react';
import { useSelector, connect } from 'react-redux'
import { compose, withProps } from "recompose"
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import mark from '../../assets/mappoint.svg'

const MyMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap,
)((props) =>
  <GoogleMap
    defaultZoom={18}
    defaultCenter={new google.maps.LatLng(36.4716999, 32.1186033)}
  >
    <Marker
      icon={mark}
      position={{ lat: 36.4716999, lng: 32.1186033 }}
    />
  </GoogleMap>
);

export const MapContact = (props) => {
  const googleAPILoaded = useSelector(({userInfo}) => userInfo.googleAPILoaded);

  //console.log('MapComponent: googleAPILoaded='+JSON.stringify(googleAPILoaded));

  if (googleAPILoaded) {
    return (<MyMapComponent isMarkerShown />);
  } else {
    return (<div></div>);
  }
};
