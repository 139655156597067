import React from 'react'
import {
  MainFooter,
  HeaderBooking,
  MainThree,
  CarsFirstBlock,
  CarsSecondBlock,
  Wrapper
} from '../../components'
import { useTranslation } from "react-i18next";

export const Cars = (props) => {
  const { t } = useTranslation();
  return (
    <div style={{ background: '#F5F5F5' }}>
      <HeaderBooking
        {...props}
        headerText={t("cars.title")}
      />
      <Wrapper>
        <CarsFirstBlock />
        <MainThree />
        <CarsSecondBlock />
      </Wrapper>
      <MainFooter />
    </div>
  )
};