import React from 'react'
import cn from "classnames"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { clearSelectData, clearLookupData, getPointList, addPoint } from '../../modules/requests/actions'
import { Button } from '../Button'
import { TextField } from '../TextField'

/*-----------------------------------------------------*/
import styles from './addressfield.module.css'

/*-----------------------------------------------------*/
const AddressFieldComponent = ({
  googleMapsKey,

  fieldnumber,
  label,
  value,
  required,

  fillCoords,
  fillDescx,
  setPointData,

  getPointList,
  selectData,
  clearSelectData
}) => {

  const { t } = useTranslation();

  const [openList, setOpenList] = React.useState(0);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [openGoogleAutocomplete, setOpenGoogleAutocomplete] = React.useState(false);

  let direction = 'down';

  const handleClick = (item) => {
    if (typeof item === "undefined") {
      item = { id: null };
    }
    setPointData(item);
    clearSelectData();
    setOpenList(0);
  };

  const handleAddAddress = (item) => {
    if (googleMapsKey && googleMapsKey.length) {
      setOpenGoogleAutocomplete(true);
    } else {
      setOpenList(0);
      setOpenPopUp(true);
    }
  };

  return (
    <div>
    {
      (openGoogleAutocomplete) ? (
        <AddressAutoComplete
          label={label}
          fillCoords={fillCoords}
          fillDescx={fillDescx}
          value={value}
          returnToEnter={() => {setOpenGoogleAutocomplete(false); setOpenList(0)}}
          required={required}
        />
      ) : (
        <div className={styles.form__field}>
          <TextField
            label={label}
            onChange={(e) => {
                       fillDescx(e);
        
                       if (e && e.target && e.target.value && e.target.value.length >= 3) {
                         getPointList(e.target.value);
                         setOpenList(fieldnumber);
                       } else {
                         setOpenList(0);
                       }
                     }}
            value={value}
            required={required}
          />
          {
            (openList === fieldnumber) && selectData.pointList && (
              <ul className={cn(styles.form__list, { [styles.form__listdown]: (direction === 'down') },
                                                   { [styles.form__listup]: (direction === 'up') })}>
              {
                (selectData.pointList.length < 2) &&
                (
                  <li className={styles.form__defaultvariant}
                      key=''
                      onClick={() => handleAddAddress()}
                  >{t((googleMapsKey && googleMapsKey.length) ? 'main.addressField.addressOutOfList'
                                                              : 'main.addressField.newAddress')}</li>
                )
              }
              {
                selectData.pointList.map((item) => (
                  <li className={styles.form__variant}
                      key={item.id}
                      onClick={() => handleClick(item)}
                  >{item.description}</li>
                ))
              }
              </ul>
            )
          }
          {
            openPopUp && (
              <div className={styles.form__popup}>
                <div className={styles.form__area}>
                  <AddAddressPopUp
                    label={t('main.newAddressPopUpForm.title')}
                    onClick={(pointData) => {
                      if (pointData) {
                        handleClick(pointData)
                      }
                      setOpenPopUp(false) }}
                  />
                </div>
              </div>
            )
          }
        </div>
      )
    }
    </div>
  )
}; /* end of AddressFieldComponent */


const AddressAutoCompleteComponent = ({
  label,
  required,
  fillCoords,
  fillDescx,
  value,
  returnToEnter
}) => {

  return (
    <div>
      <GooglePlacesAutocomplete
        onSelect={fillCoords}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['tr'],
          }
        }}
        renderInput={(props) => (
          <AddressAutoCompleteField
            label={label}
            fillDescx={fillDescx}
            props={props}
            value={value}
            onFocus={returnToEnter}
            required={required}
          />
        )}
      />
    </div>
  )
}; /* end of AddressAutoCompleteComponent */

const AddressAutoCompleteFieldComponent = ({
  label,
  required,
  fillDescx,
  props,
  value,
  onFocus
}) => {

  const [initField, setInitField] = React.useState(false);

  React.useEffect(() => {
    if( !initField) 
    {
      setInitField(true);
      props.onChange({target: {value: value}});
    };
  }, [initField]);

  return (
    <TextField
      label={label}
      onFocus={onFocus}
      onChange={(e) => {fillDescx(e); props.onChange(e)}}
      value={value}
      required={required}
    />
  );
}; /* end of AddressAutocompleteFieldComponent */

const AddAddressPopUpComponent = ({
  lookupData,
  lookupDataMode,
  clearLookupData,

  addPoint,

  label,
  onClick,
}) => {
  const { t } = useTranslation();

  const [userEnteredLat, setUserEnteredLat] = React.useState('');
  const [userEnteredLon, setUserEnteredLon] = React.useState('');
  const [userEnteredDsc, setUserEnteredDsc] = React.useState('');

  const isValid = userEnteredLat.length && userEnteredLon.length && userEnteredDsc.length;

  React.useEffect(() => {
    if (lookupData.pointId !== undefined && lookupData.pointId !== null) 
    {
      //alert ("Адрес успешно добавлен, ID="+lookupData.pointId);

      let newPointData = {
        id: lookupData.pointId,
        latitude: userEnteredLat,
        longitude: userEnteredLon,
        description: userEnteredDsc,
        tariffZoneId: lookupData.tariffZoneId };

      clearLookupData();
      onClick(newPointData);
    };
  }, [lookupData.pointId]);

  const handleClick = (pointData) => {
    if (pointData) {
      addPoint(pointData);
    }
  };

  return (
    <div className={styles.form}>
      <h1 className={styles.title}>
        {label}
      </h1>

      <div className={styles.input}>
        <table width="100%">
          <tr>
            <td width="50%">
              <TextField
                label={t('main.newAddressPopUpForm.latitude')}
                onChange={(e) => setUserEnteredLat(e.target.value)}
                value={userEnteredLat}
                required
                bordered
              />
            </td>
            <td width="50%">
              <TextField
                label={t('main.newAddressPopUpForm.longitude')}
                onChange={(e) => setUserEnteredLon(e.target.value)}
                value={userEnteredLon}
                required
                bordered
              />
            </td>
          </tr>
          <tr>
            <td width="100%" colspan="2">
              <TextField
                label={t('main.newAddressPopUpForm.description')}
                onChange={(e) => setUserEnteredDsc(e.target.value)}
                value={userEnteredDsc}
                required
                bordered
              />
            </td>
          </tr>
        </table>
      </div>

      <div className={styles.divider}/>

      <div className={styles.buttonsareaWrapper}>
        <Button
          text={t('main.newAddressPopUpForm.buttons.submit')}
          onClick={() => {
            handleClick({ latitude: userEnteredLat,
                          longitude: userEnteredLon,
                          description: userEnteredDsc });
            clearLookupData();
          }}
          disabled={!isValid}
          indented
        />
        <Button
          text={t('main.newAddressPopUpForm.buttons.closeWindow')}
          onClick={() => {
            onClick(null);
            clearLookupData();
          }}
          closeButton
          indented
        />
      </div>
    </div>
  )
}; /* end of AddAddressPopUpComponent */



/// AddressField containers
const mapAddrFieldStateToProps = ({userInfo, payment}) => ({
  googleMapsKey: userInfo.googleMapsKey,

  selectData:  payment.selectData,
  selectDataMode: payment.selectDataMode,

  lookupData:  payment.lookupData,
  lookupDataMode: payment.lookupDataMode,
});

const mapAddrFieldDispatchToProps = dispatch => bindActionCreators({
  getPointList,
  addPoint,

  clearSelectData,
  clearLookupData
}, dispatch);

export const AddressField = connect(
  mapAddrFieldStateToProps,
  mapAddrFieldDispatchToProps
)(AddressFieldComponent);

const AddressAutoComplete = connect(
)(AddressAutoCompleteComponent);

const AddressAutoCompleteField = connect(
)(AddressAutoCompleteFieldComponent);

const AddAddressPopUp = connect(
  mapAddrFieldStateToProps,
  mapAddrFieldDispatchToProps
)(AddAddressPopUpComponent);
