import React from "react"
import styles from './textfield.module.css'
import cn from 'classnames'

export const TextField = ({
  label = 'Label',
  type = 'text',
  value,
  onFocus,
  onChange,
  defaultValue,
  min = 0,
  required,
  disabled,
  bordered
}) => (
  <label htmlFor="inp" className={cn(styles.inp, {[styles.bordered]: bordered})}>
      <input
        type={type}
        placeholder="&nbsp;"
        onFocus={onFocus}
        onChange={onChange}
        defaultValue={defaultValue}
        min={min}
        value={value}
        disabled={disabled}
      />
      <span className={styles.label}>
        {label}
        {
          required && (
            <span> *</span>
          )
        }
      </span>
      <span className={styles['focus-bg']} />
  </label>
);
