import React from 'react'
import styles from './carsSecondBlock.module.css';
import { useTranslation } from "react-i18next";

import music from '../../assets/carsPage/music.png'
import drink from '../../assets/carsPage/drink.png'
import wifi from '../../assets/carsPage/wifi-24px.png'
import offline_bolt from '../../assets/carsPage/offline_bolt-24px.png'
import flight_land from '../../assets/carsPage/flight_land-24px.png'
import bag from '../../assets/carsPage/bag.png'

const blockContent = [
  {
    text: 'cars.multimedia',
    img: music,
  },
  {
    text: 'cars.bar',
    img: drink,
  },
  {
    text: 'cars.wifi',
    img: wifi,
  },
  {
    text: 'cars.usb',
    img: offline_bolt,
  },
  {
    text: 'cars.tracking',
    img: flight_land,
  },
  {
    text: 'cars.service',
    img: bag,
  },
];

export const CarsSecondBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <span className={styles.title}>
          {t("cars.premium")}
        </span>
        <p className={styles.text}>
          {t("cars.premiumTextThree")}
        </p>
        <p className={styles.text}>
          {t("cars.premiumTextFour")}
        </p>
      </div>
    </div>
  )
};
