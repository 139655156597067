export const SET_TOUR_DATE = 'set/tourDate';
export const SET_PASSENGERS = 'set/passengers';
export const SET_DESCRIPTION_TOUR = 'set/descriptionTour';
export const SET_TRANSFER_CLASS = 'set/transferClass';

export const SET_FIRST_NAME = 'set/firstName';
export const SET_LAST_NAME = 'set/lastName';
export const SET_PHONE = 'set/phone';
export const SET_LAGGUAGE_SPACE = 'set/lagguageSpace';
export const SET_CHILD_SPACE = 'set/childSpace';
export const SET_FLIGHT_NUMBER = 'set/flightNumber';
export const SET_EMAIL = 'set/email';



export const infoActionTypes = 
{
  SET_GOOGLEMAPS_KEY:    'set/googleMaps/key',
  SET_GOOGLEAPI_LOAD:    'set/googleAPI/load',

  SET_ORDER_ID:          'set/orderId',
  SET_ORDER_AMT:         'set/orderAmt',
  SET_ORDER_CURR:        'set/orderCurr',
  SET_START_POINT:       'set/startPoint',
  SET_END_POINT:         'set/endPoint',
  SET_START_DATE:        'set/startDate',
  SET_END_DATE:          'set/endDate',
  SET_START_TIME:        'set/startTime',
  SET_END_TIME:          'set/endTime',
  SET_ORDER_INFO:        'set/orderInfo',

  SET_CAR_LIST:          'set/carList',
  SET_CARTYPE_LIST:      'set/carTypeList',

  SET_NEED_RECEIPT:      'set/needReceipt',
  SET_RECEIPT_COMPANY:   'set/receiptCompany',
  SET_RECEIPT_DETAILS:   'set/receiptDetails',
  SET_RECEIPT_FIRSTNAME: 'set/receiptFirstName',
  SET_RECEIPT_LASTNAME:  'set/receiptLastName',

  SET_ONLINE_PAYMENT:    'set/onlinePayment',
  SET_CARD_PAN:          'set/cardPAN',
  SET_CARD_EXPDATE:      'set/cardExpDate',
  SET_CARD_CVV2:         'set/cardCVV2',
  SET_AUTH_AMT:          'set/authAmt',
  SET_AUTH_CURR:         'set/authCurr',

  SET_DOCUMENT_NAME:     'set/documentName',

  CLEAR_FIELD:           'clear/field'
};
