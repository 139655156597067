export function isValidPoint (pointData) {

  if (!pointData)
    return false;

  if (pointData.latitude === undefined || pointData.latitude === null || pointData.latitude === '')
    return false;

  if (pointData.longitude === undefined || pointData.longitude === null || pointData.longitude === '')
    return false;

  return true;
};
