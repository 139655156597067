import moment from 'moment';
import {
  SET_TRANSFER_CLASS,
  SET_DESCRIPTION_TOUR,
  SET_PASSENGERS,
  SET_TOUR_DATE,
  SET_CHILD_SPACE,
  SET_FIRST_NAME,
  SET_FLIGHT_NUMBER,
  SET_LAGGUAGE_SPACE,
  SET_LAST_NAME,
  SET_PHONE,
  SET_EMAIL,
} from './constants'
import { infoActionTypes } from '../info/constants'

const initialState = {
  googleMapsKey: null,
  googleAPILoaded: false,
  orderId: null,
  startPoint: {
    name: ''
  },
  endPoint: {
    name: ''
  },
  startDate: null,
  endDate: null,
  tourDate: null,
  passengers: null,
  childSpace: null,
  lagguageSpace: 5,

  orderAmount: null,
  orderCurrency: null,
  descriptionTour: '',
  transferClass: null,

  firstName: '',
  lastName: '',
  phone: '',
  flightNumber: '',
  email: '',

  needReceipt: false,
  receiptFirstName: '',
  receiptLastName: '',
  receiptCompany: '',
  receiptDetails: '',

  onlinePayment: null,
  cardPAN: '',
  cardExpDate: '',
  cardCVV2: '',
  returnCode: null,
  maskedPan: '',
  orderNumber: null,
  transactionId: '',
  receiptRequired: false,
  documentName: ''
};

export default (state = initialState, action) => {

  switch (action.type) {
    case infoActionTypes.SET_GOOGLEAPI_LOAD:
      return {
        ...state,
        googleAPILoaded: action.payload.loadFlag
      };

    case infoActionTypes.SET_GOOGLEMAPS_KEY:
      {
        let useGoogleMapsFlag = false;
        let googleMapsKey = '';

        for (const item of action.payload.settings) {
          switch (item.entity) {
            case 'useGoogleMapsFlag':  useGoogleMapsFlag = item.value;  break;
            case 'googleKey':          googleMapsKey = item.value;      break;
            default:  break;
          }
        }

        if (!useGoogleMapsFlag) {
          googleMapsKey='';
        }

        return {
          ...state,
          googleMapsKey: googleMapsKey
        }
      }

    case infoActionTypes.SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload
      };

    case infoActionTypes.SET_ORDER_AMT:
      return {
        ...state,
        orderAmount: +action.payload
      };

    case infoActionTypes.SET_ORDER_CURR:
      return {
        ...state,
        orderCurrency: action.payload
      };


    case infoActionTypes.SET_ORDER_INFO:
      state.orderId = action.payload.id;
      if (action.payload.route) {
         if (action.payload.route.startPoint) {
            state.startPoint = {
               name: action.payload.route.startPoint.description,
               latitude: action.payload.route.startPoint.latitude,
               longitude: action.payload.route.startPoint.longitude,
               tariffZoneId: action.payload.route.startPoint.tariffZoneId
            }
         }
         if (action.payload.route.endPoint) {
            state.endPoint = {
               name: action.payload.route.endPoint.description,
               latitude: action.payload.route.endPoint.latitude,
               longitude: action.payload.route.endPoint.longitude,
               tariffZoneId: action.payload.route.endPoint.tariffZoneId
            }
         }
         state.startDate = action.payload.route.directDate ? moment(action.payload.route.directDate).toDate() : '';
         state.endDate = action.payload.route.returnDate ? moment(action.payload.route.returnDate).toDate() : '';
      }
      if (action.payload.passengers) {
         state.passengers = action.payload.passengers.totalCount;
         state.childSpace = action.payload.passengers.childCount;
         state.lagguageSpace = action.payload.passengers.luggageCount;
      }
      if (action.payload.cost) {
         state.orderAmount = action.payload.cost.amount;
         state.orderCurrency = action.payload.cost.currency;
      }
      if (action.payload.client) {
         state.firstName = action.payload.client.name;
         state.lastName = /*action.payload.personInfo.lastName*/ '';
         state.phone = action.payload.client.phone;
         state.email = action.payload.client.mail;
      }
      state.flightNumber = /*action.payload.flightNumber*/ '';
      state.descriptionTour = action.payload.clientNotes;
      if (action.payload.payments && action.payload.payments[0]) {
         state.maskedPan = action.payload.payments && action.payload.payments[0].maskedPan;
         state.returnCode = action.payload.payments && action.payload.payments[0].returnCode;
         state.transactionId = action.payload.payments && action.payload.payments[0].transactionId;
      }
      state.receiptRequired = !!action.payload.receiptRequired;

      return {
        ...state
      };

    case infoActionTypes.SET_NEED_RECEIPT:
      return {
        ...state,
        needReceipt: action.payload
      }
    case infoActionTypes.SET_RECEIPT_COMPANY:
      return {
        ...state,
        receiptCompany: action.payload
      }
    case infoActionTypes.SET_RECEIPT_DETAILS:
      return {
        ...state,
        receiptDetails: action.payload
      }
    case infoActionTypes.SET_RECEIPT_FIRSTNAME:
      return {
        ...state,
        receiptFirstName: action.payload
      }
    case infoActionTypes.SET_RECEIPT_LASTNAME:
      return {
        ...state,
        receiptLastName: action.payload
      }

    case infoActionTypes.SET_ONLINE_PAYMENT:
      return {
        ...state,
        onlinePayment: action.payload.onlinePaymentFlag
      };
    case infoActionTypes.SET_CARD_PAN:
      return {
        ...state,
        cardPAN: action.payload
      }
    case infoActionTypes.SET_CARD_EXPDATE:
      return {
        ...state,
        cardExpDate: action.payload
      }
    case infoActionTypes.SET_CARD_CVV2:
      return {
        ...state,
        cardCVV2: action.payload
      }

    case infoActionTypes.SET_AUTH_AMT:
      return {
        ...state,
        authAmount: +action.payload
      };

    case infoActionTypes.SET_AUTH_CURR:
      return {
        ...state,
        authCurrency: action.payload
      };

    case infoActionTypes.SET_START_POINT:
      return {
        ...state,
        startPoint: action.payload
      }
    case infoActionTypes.SET_END_POINT:
      return {
        ...state,
        endPoint: action.payload
      }

    case infoActionTypes.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      }
    case infoActionTypes.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload
      }
    case infoActionTypes.SET_START_TIME:
    {
      const timeParts = action.payload ? action.payload.split(":") : [0, 0];
      if(timeParts[0] === undefined) {
        timeParts[0] = '';
      }
      if(timeParts[1] === undefined) {
        timeParts[1] = '';
      }

      let newStartDate = state.startDate || new Date();
      newStartDate.setHours(timeParts[0], timeParts[1], 0);

      return {
        ...state,
        startDate: newStartDate
      }
    }
    case infoActionTypes.SET_END_TIME:
    {
      const timeParts = action.payload ? action.payload.split(":") : [0, 0];
      if(timeParts[0] === undefined) {
        timeParts[0] = '';
      }
      if(timeParts[1] === undefined) {
        timeParts[1] = '';
      }

      let newEndDate = state.endDate || new Date();
      newEndDate.setHours(timeParts[0], timeParts[1], 0);

      return {
        ...state,
        endDate: newEndDate
      }
    }
    case SET_TOUR_DATE:
      return {
        ...state,
        tourDate: action.payload
      }
    case SET_PASSENGERS:
      return {
        ...state,
        passengers: action.payload
      }
    case SET_DESCRIPTION_TOUR:
      return {
        ...state,
        descriptionTour: action.payload
      }
    case SET_TRANSFER_CLASS:
      return {
        ...state,
        transferClass: action.payload
      }
    case SET_CHILD_SPACE:
      return {
        ...state,
        childSpace: action.payload
      }
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload
      }
    case SET_FLIGHT_NUMBER:
      return {
        ...state,
        flightNumber: action.payload
      }
    case SET_LAGGUAGE_SPACE:
      return {
        ...state,
        lagguageSpace: action.payload
      }
    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload
      }
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload
      }
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload
      }

    case infoActionTypes.SET_DOCUMENT_NAME:
      return {
        ...state,
        documentName: action.payload.documentName
      };

    case infoActionTypes.SET_CAR_LIST:
      return {
        ...state,
        carList: action.payload.carList
      };

    case infoActionTypes.SET_CARTYPE_LIST:
      return {
        ...state,
        carTypeList: action.payload.carTypeList
      };

    case infoActionTypes.CLEAR_FIELD:
      return initialState;

    default:
      return state
  }
}
