import React from 'react'
import {
  MainFooter,
  HeaderBooking,
  Wrapper
} from '../../components'
import { useTranslation } from "react-i18next";
import styles from './privacy.module.css';

export const Privacy = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <HeaderBooking
        {...props}
        headerText={t("privacy.title")}
      />
      <Wrapper>
        <div className={styles.body}>
          Coming soon
        </div>
      </Wrapper>
      <MainFooter />
    </div>
  )
};
