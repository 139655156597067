import React from 'react'
import {
  MainFooter,
  HeaderBooking,
  Wrapper
} from '../../components'
import { useTranslation } from "react-i18next";
import styles from './contacts.module.css';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { clearFields, setGoogleAPILoaded } from '../../modules/info/actions'
import { getGoogleMapsKey } from '../../modules/requests/actions'
import { MapContact } from '../../components/MapContact'

import faccebook_messager from '../../assets/contacts/icons8-facebook-messenger 1.svg'
import faccebook from '../../assets/contacts/icons8-facebook.svg'
import instagramm from '../../assets/contacts/icons8-instagram.svg'
import twitter from '../../assets/contacts/icons8-twitter.svg'
import time from '../../assets/contacts/mdi_access_time.svg'
import location from '../../assets/contacts/mdi_location_on.svg'
import phone from '../../assets/contacts/mdi_phone.svg'
import mail from '../../assets/contacts/mdi_mail_outline.svg'
import telegram from '../../assets/contacts/telegram.svg'
import viber from '../../assets/contacts/viber.svg'
import whatsapp from '../../assets/contacts/whatsapp.svg'

const ContactsPageComponent = (props) => {
  const { t } = useTranslation();

  const [contactsPageState, setContactsPageState] = React.useState(0);
  const [googleState, setGoogleState] = React.useState('');

  // Cleanup previous data and Connect to Google Maps
  React.useEffect(() => {
    //console.log('-> contactsPageState='+contactsPageState+'; googleMapsKey='+props.googleMapsKey+'; googleState='+googleState);
    if (contactsPageState === 0) {

      props.clearFields();
      setContactsPageState(1);
    } else {
      if (googleState === '') {

        if (props.googleMapsKey === null || props.googleMapsKey === undefined) {
          props.getGoogleMapsKey();
        } else if (props.googleMapsKey.length) {
          const script = document.createElement("script");

          script.type="text/javascript";
          script.src="https://maps.googleapis.com/maps/api/js?key="+props.googleMapsKey+"&libraries=places,establishment,geometry,drawing";
          script.addEventListener("load", () => { console.log('script loaded'); setGoogleState('complete')});
          script.addEventListener("error", () => { console.log('script load error'); setGoogleState('error')});

          document.body.appendChild(script);
          setGoogleState('loading');
        }
      } else if (googleState === 'complete') {
        props.setGoogleAPILoaded(true);
      }
    }
  }, [contactsPageState, props.googleMapsKey, googleState]);

  return (
    <div className={styles.container}>
      <HeaderBooking
        {...props}
        headerText={t("contacts.title")}
      />
      <Wrapper>
        <div className={styles.body}>
          <div className={styles.left}>
            <div className={styles.infos}>
              <a href="https://www.google.com/maps/place/Aura+2/@36.4716999,32.1186033,17z/data=!3m1!4b1!4m5!3m4!1s0x14dc9117e1dc7039:0xce9694c4b5f7c29f!8m2!3d36.4716999!4d32.1207973" className={styles.info}>
                <img alt="/" src={location} />
                {t("contacts.address1")}<br />
                {t("contacts.address2")}
              </a>
              <div className={styles.info}>
                <img alt="/" src={time} />
                08:00 – 20:00 {t("contacts.everyday")}
              </div>
              <div className={styles.info}>
                <img alt="/" src={phone} />
                <a href="tel:+90 552 614 56 69" className={styles.phone}>+90 552 614 56 69</a>
                <div className={styles.socialIcons}>
                  <a href="tel:+90 552 614 56 69"><img alt="/" src={telegram} /></a>
                  <a href="tel:+90 552 614 56 69"><img alt="/" src={viber} /></a>
                  <a href="tel:+90 552 614 56 69"><img alt="/" src={whatsapp} /></a>
                </div>
              </div>
              <div className={styles.info}>
                <img alt="/" src={mail} />
                <a href="mailto: ATM.Transfer247@gmail.com">ATM.Transfer247@gmail.com</a>
              </div>
            </div>
            <div className={styles.socials}>
              <div className={styles.title}>
                {t("contacts.text")}
              </div>
              <div className={styles.links}>
                <a href="https://www.facebook.com/ATM-VIP-Transfer-111421627251195/" className={styles.link}>
                  <img alt="/" src={faccebook} />
                  /24-7_transfer
                </a>
                <a href="https://www.facebook.com/ATM-VIP-Transfer-111421627251195/" className={styles.link}>
                  <img alt="/" src={faccebook_messager} />
                  {t("contacts.message")}
                </a>
                <a href="https://www.instagram.com/atm_vip_transfer/" className={styles.link}>
                  <img alt="/" src={instagramm} />
                  /transfer_in_turkey
                </a>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.wrapperMap}>
              <MapContact />
            </div>
          </div>
        </div>
      </Wrapper>
      <MainFooter />
    </div>
  )
};

const mapStateToProps = ({ userInfo }) => ({
  googleMapsKey: userInfo.googleMapsKey
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearFields,
  getGoogleMapsKey,
  setGoogleAPILoaded
}, dispatch);

export const ContactsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactsPageComponent);