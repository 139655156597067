import React from 'react'
import styles from './bookingcontroll.module.css';
import Processing from '../../assets/Processing.png'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import './animation.css'

export const ProgressComponent = ({ step, setStep, onlinePayment, pays, cardInfo }) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    if (!onlinePayment) {
      setStep(4);
    }
    if ((pays.error) || (cardInfo.data && cardInfo.data.error)) {
      setStep(5);
    }
    if (cardInfo.data && !cardInfo.data.error) {
      document.getElementById("3dpayment").submit()
    }
  }, [onlinePayment, pays, cardInfo.data]);

  return (
    <div>
      {
        cardInfo.data && (
          <form id="3dpayment" action={cardInfo.data.endpoint} method="post">
            <input type="hidden" name="clientid" value={cardInfo.data.clientid}/>
            <input type="hidden" name="storetype" value={cardInfo.data.storetype}/>
            <input type="hidden" name="hash" value={cardInfo.data.hash}/>
            <input type="hidden" name="trantype" value={cardInfo.data.trantype}/>
            <input type="hidden" name="amount" value={cardInfo.data.amount}/>
            <input type="hidden" name="currency" value={cardInfo.data.currency}/>
            <input type="hidden" name="oid" value={cardInfo.data.oid}/>
            <input type="hidden" name="okurl" value={cardInfo.data.okurl}/>
            <input type="hidden" name="failurl" value={cardInfo.data.failurl}/>
            <input type="hidden" name="lang" value={cardInfo.data.lang}/>
            <input type="hidden" name="rnd" value={cardInfo.data.rnd}/>
            <input type="hidden" name="pan" value={cardInfo.data.pan} size="20"/>
            <input type="hidden" name="Ecom_Payment_Card_ExpDate_Year" value={cardInfo.data.expyear}/>
            <input type="hidden" name="Ecom_Payment_Card_ExpDate_Month" value={cardInfo.data.expmonth}/>
            <input type="hidden" name="cv2" value={cardInfo.data.cv2} size="4"/>
            <input type="hidden" name="encoding" value={cardInfo.data.encoding}/>
          </form>
        )
      }
      <h1 className={styles.title}>
        {t("progress.title")}
      </h1>
      <div className={styles.progress}>
        <img alt="/" src={Processing} className={'loading'} />
      </div>
      <span className={styles.textBold}>
        {t("progress.awating")}
      </span>
      <span className={styles.textLight}>
        {t("progress.textOne")}
        {t("progress.textTwo")} <span>example@gmail.com</span> {t("progress.textThree")} <span>8 800 555 25 25</span>
      </span>
    </div>
  )
};

const mapStateToProps = ({ payment, userInfo }) => ({
  onlinePayment: userInfo.onlinePayment,
  cardInfo: payment.cardInfo,
  pays: payment.pays,
});

export const Progress = connect(
  mapStateToProps,
)(ProgressComponent);
