import React from 'react'
import styles from './selectheader.module.css';
import cn from 'classnames'

const defaultContent = [
  {
    value: 'USD',
    label: '$, USD'
  },
  {
    value: 'EUR',
    label: '€, EUR'
  },
  {
    value: 'TRY',
    label: '₺, TRY'
  },
  {
    value: 'RUB',
    label: '₽, RUB'
  },
];

export const SelectHeader = ({ value, content = defaultContent, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e, value) => {
    onChange(value);
    setOpen(false)
  };
  return (
    <div className={styles.conteiner}>
      <div className={styles.value} onClick={() => setOpen(true)}>{value.label}</div>
      {
        open && (
          <div className={styles.content}>
            {
              content.map((item) => (
                <div
                  key={item.value}
                  className={cn(styles.value, styles.listContent)}
                  onClick={(e) => handleOpen(e, item)}
                >
                  {item.label}
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  )
};
